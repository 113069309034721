/**
 * 值班管理接口
 */

const prefix = '/dseWeb'
export default [
  // 分页查询值班信息
  {
    name: 'getDutyInfos',
    url: `${prefix}/v1/op/dutyInfos`,
    method: 'get'
  },
  // 新增值班信息
  {
    name: 'addDutyInfo',
    url: `${prefix}/v1/op/dutyInfo`,
    method: 'post'
  },
  // 删除值班信息
  {
    name: 'deleteDutyInfo',
    url: `${prefix}/v1/op/dutyInfo`,
    method: 'delete'
  },
  // 查询值班日历详情
  {
    name: 'getDutyCalendarDetail',
    url: `${prefix}/v1/op/dutyCalendarDetail`,
    method: 'get'
  },
  // 新增值班日历
  {
    name: 'addDutyCalendar',
    url: `${prefix}/v1/op/dutyCalendar`,
    method: 'post'
  },
  // 新增班次信息
  {
    name: 'addDutyTimeItem',
    url: `${prefix}/v1/op/dutyTimeItem`,
    method: 'post'
  },
  // 查询班次时间集合
  {
    name: 'getEveryTimeItems',
    url: `${prefix}/v1/op/everyTimeItems`,
    method: 'get'
  },
  // 查询值班定时任务详情
  {
    name: 'getDutyTimesTaskByDuty',
    url: `${prefix}/v1/op/dutyTimesTaskByDuty`,
    method: 'get'
  },
  // 新增或编辑值班定时任务
  {
    name: 'addDutyTimesTask',
    url: `${prefix}/v1/op/dutyTimesTask`,
    method: 'post'
  },
  // 分页查询值班日历记录
  {
    name: 'getDutyCalendarRecords',
    url: `${prefix}/v1/op/dutyCalendarRecords`,
    method: 'get'
  },
  // 新增值班日历记录
  {
    name: 'addDutyCalendarRecord',
    url: `${prefix}/v1/op/dutyCalendarRecord`,
    method: 'post'
  },
  // 删除班次信息
  {
    name: 'deleteDutyTimeItem',
    url: `${prefix}/v1/op/dutyTimeItem`,
    method: 'delete'
  },
  // 修改班次信息
  {
    name: 'updateDutyTimeItem',
    url: `${prefix}/v1/op/dutyTimeItem`,
    method: 'put'
  },
  // 新增班次人员信息
  {
    name: 'addDutyTimeItemUser',
    url: `${prefix}/v1/op/dutyTimeItemUser`,
    method: 'post'
  },
  // 修改班次人员信息
  {
    name: 'updateDutyTimeItemUser',
    url: `${prefix}/v1/op/dutyTimeItemUser`,
    method: 'put'
  },
  // 查询单个值班日历记录
  {
    name: 'getDutyCalendarRecord',
    url: `${prefix}/v1/op/dutyCalendarRecord`,
    method: 'get'
  },
  // 查询班次提交记录
  {
    name: 'getSubmitDutyTimeItems',
    url: `${prefix}/v1/op/submitDutyTimeItems`,
    method: 'get'
  },
  // 查询单个值班信息
  {
    name: 'getDutyInfo',
    url: `${prefix}/v1/op/dutyInfo`,
    method: 'get'
  },
  // 查询值班日历集合
  {
    name: 'getDutyCalendars',
    url: `${prefix}/v1/op/dutyCalendars`,
    method: 'get'
  },
  // 查询班次信息集合
  {
    name: 'getDutyTimeItems',
    url: `${prefix}/v1/op/dutyTimeItems`,
    method: 'get'
  },
  // 值班日历保存
  {
    name: 'dutyCalendarSave',
    url: `${prefix}/v1/op/dutyCalendarSave`,
    method: 'post'
  },
  // 沿用上月值班排班
  {
    name: 'lastMonthCalendarSave',
    url: `${prefix}/v1/op/lastMonthCalendarSave`,
    method: 'post'
  },
  // 新增值班日历总结信息
  {
    name: 'dutyCalendarSummary',
    url: `${prefix}/v1/op/dutyCalendarSummary`,
    method: 'post'
  },
  // 查询值班集合
  {
    name: 'getDutySelections',
    url: `${prefix}/v1/op/dutySelections`,
    method: 'get'
  },
  // 日志填报记录查询
  {
    name: 'getWorkLogList',
    url: `${prefix}/v1/op/getWorkLogList`,
    method: 'post'
  },
  // 新增或修改工作日志
  {
    name: 'saveOrUpdateWorkLog',
    url: `${prefix}/v1/op/saveOrUpdateWorkLog`,
    method: 'post'
  },
  // 通过id获取工作日志
  {
    name: 'getWorkLogById',
    url: `${prefix}/v1/op/getWorkLogById`,
    method: 'post'
  },
  // 导出工作日志
  {
    name: 'exportWorkLogList',
    url: `${prefix}/v1/op/exportWorkLogList`,
    method: 'post',
    config: {
      responseType: 'blob'
    }
  }
]
