/**
 * 值班管理接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 分页查询值班信息
    async fetchGetDutyInfos({ commit, getters }, params) {
      const { getDutyInfos } = getters.getHttps
      const { data } = await getDutyInfos(params)
      return setStoreApiDate(data, commit)
    },
    // 新增值班信息
    async fetchAddDutyInfo({ commit, getters }, params) {
      const { addDutyInfo } = getters.getHttps
      const { data } = await addDutyInfo(params)
      return setStoreApiDate(data, commit)
    },
    // 新增值班信息
    async fetchDeleteDutyInfo({ commit, getters }, dutyId) {
      const { deleteDutyInfo } = getters.getHttps
      const { data } = await deleteDutyInfo(false, false, {
        url: `${prefix}/v1/op/dutyInfo/${dutyId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 查询值班日历详情
    async fetchGetDutyCalendarDetail({ commit, getters }, params) {
      const { getDutyCalendarDetail } = getters.getHttps
      const { data } = await getDutyCalendarDetail(params)
      return setStoreApiDate(data, commit)
    },
    // 新增值班日历
    async fetchAddDutyCalendar({ commit, getters }, params) {
      const { addDutyCalendar } = getters.getHttps
      const { data } = await addDutyCalendar(params)
      return setStoreApiDate(data, commit)
    },
    // 新增班次信息
    async fetchAddDutyTimeItem({ commit, getters }, params) {
      const { addDutyTimeItem } = getters.getHttps
      const { data } = await addDutyTimeItem(params)
      return setStoreApiDate(data, commit)
    },
    // 查询班次时间集合
    async fetchGetEveryTimeItems({ commit, getters }, params) {
      const { getEveryTimeItems } = getters.getHttps
      const { data } = await getEveryTimeItems(params)
      return setStoreApiDate(data, commit)
    },
    // 查询值班定时任务详情
    async fetchGetDutyTimesTaskByDuty({ commit, getters }, dutyId) {
      const { getDutyTimesTaskByDuty } = getters.getHttps
      const { data } = await getDutyTimesTaskByDuty(false, false, {
        url: `${prefix}/v1/op/dutyTimesTaskByDuty/${dutyId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 新增或编辑值班定时任务
    async fetchAddDutyTimesTask({ commit, getters }, params) {
      const { addDutyTimesTask } = getters.getHttps
      const { data } = await addDutyTimesTask(params)
      return setStoreApiDate(data, commit)
    },
    // 分页查询值班日历记录
    async fetchGetDutyCalendarRecords({ commit, getters }, params) {
      const { getDutyCalendarRecords } = getters.getHttps
      const { data } = await getDutyCalendarRecords(params)
      return setStoreApiDate(data, commit)
    },
    // 新增值班日历记录
    async fetchAddDutyCalendarRecord({ commit, getters }, params) {
      const { addDutyCalendarRecord } = getters.getHttps
      const { data } = await addDutyCalendarRecord(params)
      return setStoreApiDate(data, commit)
    },
    // 删除班次信息
    async fetchDeleteDutyTimeItem({ commit, getters }, classId) {
      const { deleteDutyTimeItem } = getters.getHttps
      const { data } = await deleteDutyTimeItem(false, false, {
        url: `${prefix}/v1/op/dutyTimeItem/${classId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 修改班次信息
    async fetchUpdateDutyTimeItem({ commit, getters }, params) {
      const { updateDutyTimeItem } = getters.getHttps
      const { data } = await updateDutyTimeItem(params)
      return setStoreApiDate(data, commit)
    },
    // 新增班次人员信息
    async fetchAddDutyTimeItemUser({ commit, getters }, params) {
      const { addDutyTimeItemUser } = getters.getHttps
      const { data } = await addDutyTimeItemUser(params)
      return setStoreApiDate(data, commit)
    },
    // 修改班次人员信息
    async fetchUpdateDutyTimeItemUser({ commit, getters }, params) {
      const { updateDutyTimeItemUser } = getters.getHttps
      const { data } = await updateDutyTimeItemUser(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单个值班日历记录
    async fetchGetDutyCalendarRecord({ commit, getters }, recordId) {
      const { getDutyCalendarRecord } = getters.getHttps
      const { data } = await getDutyCalendarRecord(false, false, {
        url: `${prefix}/v1/op/dutyCalendarRecord/${recordId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 查询班次提交记录
    async fetchGetSubmitDutyTimeItems({ commit, getters }, params) {
      const { getSubmitDutyTimeItems } = getters.getHttps
      const { data } = await getSubmitDutyTimeItems(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单个值班信息
    async fetchGetDutyInfo({ commit, getters }, dutyId) {
      const { getDutyInfo } = getters.getHttps
      const { data } = await getDutyInfo(false, false, {
        url: `${prefix}/v1/op/dutyInfo/${dutyId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 查询值班日历集合
    async fetchGetDutyCalendars({ commit, getters }, params) {
      const { getDutyCalendars } = getters.getHttps
      const { data } = await getDutyCalendars(params)
      return setStoreApiDate(data, commit)
    },
    // 查询班次信息集合
    async fetchGetDutyTimeItems({ commit, getters }, params) {
      const { getDutyTimeItems } = getters.getHttps
      const { data } = await getDutyTimeItems(params)
      return setStoreApiDate(data, commit)
    },
    // 值班日历保存
    async fetchDutyCalendarSave({ commit, getters }, params) {
      const { dutyCalendarSave } = getters.getHttps
      const { data } = await dutyCalendarSave(params)
      return setStoreApiDate(data, commit)
    },
    // 沿用上月值班排班
    async fetchLastMonthCalendarSave({ commit, getters }, params) {
      const { lastMonthCalendarSave } = getters.getHttps
      const { data } = await lastMonthCalendarSave(params)
      return setStoreApiDate(data, commit)
    },
    // 新增值班日历总结信息
    async fetchDutyCalendarSummary({ commit, getters }, params) {
      const { dutyCalendarSummary } = getters.getHttps
      const { data } = await dutyCalendarSummary(params)
      return setStoreApiDate(data, commit)
    },
    // 查询值班集合
    async fetchGetDutySelections({ commit, getters }) {
      const { getDutySelections } = getters.getHttps
      const { data } = await getDutySelections()
      return setStoreApiDate(data, commit)
    },
    // 日志填报记录查询
    async fetchGetWorkLogList({ commit, getters }, params) {
      const { getWorkLogList } = getters.getHttps
      const { data } = await getWorkLogList(params)
      return setStoreApiDate(data, commit)
    },
    // 新增或修改工作日志
    async fetchSaveOrUpdateWorkLog({ commit, getters }, params) {
      const { saveOrUpdateWorkLog } = getters.getHttps
      const { data } = await saveOrUpdateWorkLog(params)
      return setStoreApiDate(data, commit)
    },
    // 通过id获取工作日志
    async fetchGetWorkLogById({ commit, getters }, params) {
      const { getWorkLogById } = getters.getHttps
      const { data } = await getWorkLogById(params)
      return setStoreApiDate(data, commit)
    },
    // 新增或修改工作日志
    async fetchExportWorkLogList({ commit, getters }, params) {
      const { exportWorkLogList } = getters.getHttps
      const { data } = await exportWorkLogList(params)
      return data
    }
  }
}
