<template>
  <!--
    @name: tip
    @describe: 标题盒子
    @author: L
    @date: 2021-05-25
 -->
  <el-card class="ml-tip" :shadow="shadow" :body-style="bodyStyle">
    <template v-if="showHeader" #header>
      <slot name="title">{{ title }}</slot>
    </template>
    <slot>{{ content }}</slot>
  </el-card>
</template>

<script>
export default {
  name: 'Tip',
  props: {
    showHeader: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: 'content'
    },
    shadow: {
      type: String,
      default: 'never'
    },
    bodyStyle: {
      type: Object,
      default: () => {
        return { padding: '20px' }
      }
    }
  }
}
</script>

<style lang="scss">
.ml-tip {
  border: 0;
  border-left: 5px solid $--color-primary;
  border-bottom: 1px dashed #ebeef5;
  background-color: #fff;
  font-size: 14px;
}
.default-tip {
  background: #fff;
  border: 1px solid #ebeef5;
}
</style>
