/**
 * 巡检计划接口
 */

const prefix = '/dseWeb'
export default [
  // 分页查询巡检计划
  { name: 'getInspectPlans', url: `${prefix}/v1/op/inspectPlans`, method: 'get' },
  // 新增巡检计划
  { name: 'addInspectPlan', url: `${prefix}/v1/op/inspectPlan`, method: 'post' },
  // 修改巡检计划
  { name: 'updateInspectPlan', url: `${prefix}/v1/op/inspectPlan`, method: 'put' },
  // 查询单个巡检计划
  { name: 'getInspectPlan', url: `${prefix}/v1/op/inspectPlan`, method: 'get' },
  // 删除巡检计划
  { name: 'removeInspectPlan', url: `${prefix}/v1/op/inspectPlan`, method: 'delete' }
]
