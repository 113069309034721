/**
 * 单位管理接口
 */

const prefix = '/dseWeb'
export default [
  // 分页查询单位信息
  { name: 'getCompanyInfos', url: `${prefix}/v1/op/companyInfos`, method: 'get' },
  // 新增单位信息
  { name: 'addCompanyInfo', url: `${prefix}/v1/op/companyInfo`, method: 'post' },
  // 修改单位信息
  { name: 'updateCompanyInfo', url: `${prefix}/v1/op/companyInfo`, method: 'put' },
  // 删除单位信息
  { name: 'deleteCompanyInfo', url: `${prefix}/v1/op/companyInfo`, method: 'delete' },
  // 查询单个单位信息
  { name: 'getCompanyInfo', url: `${prefix}/v1/op/companyInfo`, method: 'get' },
  // 单位负责人登录
  { name: 'companyManagerLogin', url: `${prefix}/v1/op/companyManagerLogin`, method: 'get' }
]
