/**
 * 季度收益接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 分页查询季度收益
    async fetchGetQuarterlyEarnings({ commit, getters }, params) {
      const { getQuarterlyEarnings } = getters.getHttps
      const { data } = await getQuarterlyEarnings(params)
      return setStoreApiDate(data, commit)
    },
    // 删除季度收益
    async fetchDeleteQuarterlyEarning({ commit, getters }, earningId) {
      const { deleteQuarterlyEarning } = getters.getHttps
      const { data } = await deleteQuarterlyEarning(false, false, {
        url: `${prefix}/v1/op/quarterlyEarning/${earningId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 添加季度收益
    async fetchAddQuarterlyEarning({ commit, getters }, params) {
      const { addQuarterlyEarning } = getters.getHttps
      const { data } = await addQuarterlyEarning(params)
      return setStoreApiDate(data, commit)
    },
    // 修改季度收益
    async fetchUpdateQuarterlyEarning({ commit, getters }, params) {
      const { updateQuarterlyEarning } = getters.getHttps
      const { data } = await updateQuarterlyEarning(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单个季度收益
    async fetchGetQuarterlyEarning({ commit, getters }, earningId) {
      const { getQuarterlyEarning } = getters.getHttps
      const { data } = await getQuarterlyEarning(false, false, {
        url: `${prefix}/v1/op/quarterlyEarning/${earningId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 分页查询季度收益记录
    async fetchGetQuarterlyEarningRecords({ commit, getters }, params) {
      const { getQuarterlyEarningRecords } = getters.getHttps
      const { data } = await getQuarterlyEarningRecords(params)
      return setStoreApiDate(data, commit)
    },
    // 删除季度收益记录
    async fetchQuarterlyEarningRecord({ commit, getters }, recordId) {
      const { quarterlyEarningRecord } = getters.getHttps
      const { data } = await quarterlyEarningRecord(false, false, {
        url: `${prefix}/v1/op/quarterlyEarningRecord/${recordId}`
      })
      return setStoreApiDate(data, commit)
    }
  }
}
