<template>
  <!--
    @name: CustomFormButton
    @describe: 表单Form 提交按钮
    @author: L
    @date: 2021-05-25
 -->
  <el-form-item>
    <slot name="submit">
      <el-button
        v-if="showSubmit"
        :icon="submitIcon"
        :size="size"
        :type="submitType"
        :disabled="disabledSubmit"
        :native-type="nativeType"
        @click="clickSubmit"
      >
        {{ submitText }}
      </el-button>
    </slot>
    <slot name="cancel">
      <el-button
        v-if="showCancel"
        :icon="cancelIcon"
        :size="size"
        :type="cancelType"
        :disabled="disabledCancel"
        @click="clickCancel"
      >
        {{ cancelText }}
      </el-button>
    </slot>
    <slot></slot>
  </el-form-item>
</template>

<script>
export default {
  name: 'CustomFormButton',
  emits: ['click-submit', 'click-cancel'],
  props: {
    // 按钮大小
    size: {
      type: String,
      default: 'medium'
    },
    // 提交按钮是否显示
    showSubmit: {
      type: Boolean,
      default: true
    },
    disabledSubmit: {
      type: Boolean,
      default: false
    },
    disabledCancel: {
      type: Boolean,
      default: false
    },
    // 提交按钮样式类型
    submitType: {
      type: String,
      default: 'primary'
    },
    // 是否为提交表彰按钮 button / submit / reset
    nativeType: {
      type: String,
      default: 'submit'
    },
    // 提交按钮文本
    submitText: {
      type: String,
      default: '确认'
    },
    // 提交按钮图标
    submitIcon: {
      type: String,
      default: ''
    },
    // 取消按钮文本
    cancelText: {
      type: String,
      default: '取消'
    },
    // 取消按钮图标
    cancelIcon: {
      type: String,
      default: 'false'
    },
    // 取消按钮是否显示
    showCancel: {
      type: Boolean,
      default: true
    },
    // 取消按钮样式类型
    cancelType: {
      type: String
    }
  },
  setup(props, context) {
    // 点击确定按钮发布订阅
    const clickSubmit = () => {
      context.emit('click-submit')
    }
    // 点击取消按钮发布订阅
    const clickCancel = () => {
      context.emit('click-cancel')
    }
    return { clickSubmit, clickCancel }
  }
}
</script>

<style></style>
