/**
 * 设备管理接口
 */

const prefix = '/dseWeb'
export default [
  // 分页查询设备信息
  {
    name: 'getDeviceInfos',
    url: `${prefix}/v1/op/deviceInfos`,
    method: 'get'
  },
  // 批量删除设备信息
  {
    name: 'deleteDeviceInfos',
    url: `${prefix}/v1/op/deviceInfos`,
    method: 'delete'
  },
  // 新增设备信息
  {
    name: 'addDeviceInfo',
    url: `${prefix}/v1/op/deviceInfo`,
    method: 'post'
  },
  // 修改设备信息
  {
    name: 'updateDeviceInfo',
    url: `${prefix}/v1/op/deviceInfo`,
    method: 'put'
  },
  // 查询单个设备信息
  {
    name: 'getDeviceInfo',
    url: `${prefix}/v1/op/deviceInfo`,
    method: 'get'
  },
  // 删除设备信息
  {
    name: 'deleteDeviceInfo',
    url: `${prefix}/v1/op/deviceInfo`,
    method: 'delete'
  },
  // 设备信息Excel模板导入
  {
    name: 'deviceExcelImport',
    url: `${prefix}/v1/op/deviceExcelImport`,
    method: 'post'
  },
  // 设备信息Excel模板导出
  {
    name: 'deviceExcelExport',
    url: `${prefix}/v1/op/deviceExcelExport`,
    method: 'get'
  },
  // 创建设备二维码
  {
    name: 'createDeviceQrCode',
    url: `${prefix}/v1/op/createDeviceQrCode`,
    method: 'post'
  },
  // 查询项目设备图层树集合 慢
  // { name: 'projectDeviceTreeList', url: `${prefix}/v1/op/projectDeviceTreeList`, method: 'get' },
  // 查询项目设备图层树集合 初始单层 快
  {
    name: 'projectDeviceTreeList',
    url: `${prefix}/v1/op/projectDeviceTreeList`,
    method: 'get'
  },
  // 查询单个阿米巴下面的数据
  {
    name: 'projectByCompanyIdDeviceTree',
    url: `${prefix}/v1/op/projectByCompanyIdDeviceTree`,
    method: 'get'
  },
  // 更新Iot产品属性
  {
    name: 'updateIotProducts',
    url: `${prefix}/v1/op/updateIotProducts`,
    method: 'post'
  },
  // 查询项目设备图层树集合 - new
  {
    name: 'getDeviceBases',
    url: `${prefix}/v1/op/deviceBases`,
    method: 'get'
  },
  // 查询设备图层总数统计
  {
    name: 'getDeviceBasesStatisticsVo',
    url: `${prefix}/v1/op/deviceBasesStatisticsVo`,
    method: 'get'
  },
  // 获取设备最新属性清单列表
  {
    name: 'lastRtuAttrList',
    url: `${prefix}/v1/lastRtuAttrList`,
    method: 'post'
  },
  // 获取设备属性历史数据列表
  {
    name: 'rtuAttrHisList',
    url: `${prefix}/v1/rtuAttrHisList`,
    method: 'post'
  },
  // 获取设备属性历史分页
  {
    name: 'rtuAttrHisPage',
    url: `${prefix}/v1/rtuAttrHisPage`,
    method: 'post'
  },
  // 导出设备属性历史数据列表
  {
    name: 'exportRtuAttrHis',
    url: `${prefix}/v1/exportRtuAttrHis`,
    method: 'post',
    config: {
      responseType: 'arraybuffer'
    }
  }
]
