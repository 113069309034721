/**
 * 备品备件库管理接口
 */

const prefix = '/dseWeb'
export default [
  // 分页查询备品备件库
  { name: 'getSpareParts', url: `${prefix}/v1/op/spareParts`, method: 'get' },
  // 新增备品备件库
  { name: 'addSparePart', url: `${prefix}/v1/op/sparePart`, method: 'post' },
  // 修改备品备件库
  { name: 'updateSparePart', url: `${prefix}/v1/op/sparePart`, method: 'put' },
  // 查询单个备品备件库
  { name: 'getSparePart', url: `${prefix}/v1/op/sparePart`, method: 'get' },
  // 删除备品备件库
  { name: 'deleteSparePart', url: `${prefix}/v1/op/sparePart`, method: 'delete' },
  // 备品备件Excel模板导入
  { name: 'sparePartExcelImport', url: `${prefix}/v1/op/sparePartExcelImport`, method: 'post' },
  // 获取审核流程列表
  { name: 'orderProcesss', url: `${prefix}/v1/op/orderProcesss`, method: 'get' },
  // 审核退回接口
  { name: 'apprProcess', url: `${prefix}/v1/op/apprProcess`, method: 'post' },
  // 根据当前处理节点
  { name: 'getCurNode', url: `${prefix}/v1/op/getCurNode`, method: 'post' },
  // 是否存在未处理完的出库/入库流程
  { name: 'existsSpareProcess', url: `${prefix}/v1/op/existsSpareProcess`, method: 'get' }
]
