/**
 * 文件资料管理接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 分页查询项目文件夹
    async fetchGetProjectFolders({ commit, getters }, params) {
      const { getProjectFolders } = getters.getHttps
      const { data } = await getProjectFolders(params)
      return setStoreApiDate(data, commit)
    },
    // 新增项目文件夹
    async fetchAddProjectFolder({ commit, getters }, params) {
      const { addProjectFolder } = getters.getHttps
      const { data } = await addProjectFolder(params)
      return setStoreApiDate(data, commit)
    },
    // 修改项目文件夹
    async fetchUpdateProjectFolder({ commit, getters }, params) {
      const { updateProjectFolder } = getters.getHttps
      const { data } = await updateProjectFolder(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单个项目文件夹
    async fetchGetProjectFolder({ commit, getters }, folderId) {
      const { getProjectFolder } = getters.getHttps
      const { data } = await getProjectFolder(false, false, {
        url: `${prefix}/v1/op/projectFolder/${folderId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 查询项目文件夹树集合
    async fetchGetProjectFolderTreeList({ commit, getters }, params) {
      const { getProjectFolderTreeList } = getters.getHttps
      const { data } = await getProjectFolderTreeList(params)
      return setStoreApiDate(data, commit)
    },
    // 删除项目文件夹
    async fecthDeleteProjectFolder({ commit, getters }, folderId) {
      const { deleteProjectFolder } = getters.getHttps
      const { data } = await deleteProjectFolder(false, false, {
        url: `${prefix}/v1/op/projectFolder/${folderId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 分页查询项目文件
    async fetchGetProjectFolderFiles({ commit, getters }, params) {
      const { getProjectFolderFiles } = getters.getHttps
      const { data } = await getProjectFolderFiles(params)
      return setStoreApiDate(data, commit)
    },
    // 新增项目文件
    async fetchAddProjectFolderFile({ commit, getters }, params) {
      const { addProjectFolderFile } = getters.getHttps
      const { data } = await addProjectFolderFile(params)
      return setStoreApiDate(data, commit)
    },
    // 删除项目文件
    async fetchDeleteProjectFolderFile({ commit, getters }, fileId) {
      const { deleteProjectFolderFile } = getters.getHttps
      const { data } = await deleteProjectFolderFile(false, false, {
        url: `${prefix}/v1/op/projectFolderFile/${fileId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 查询单个项目文件
    async fetchGetProjectFolderFile({ commit, getters }, fileId) {
      const { getProjectFolderFile } = getters.getHttps
      const { data } = await getProjectFolderFile(false, false, {
        url: `${prefix}/v1/op/projectFolderFile/${fileId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 查文件夹下的文件夹
    async fetchGetProjectFolderSupers({ commit, getters }, params) {
      const { getProjectFolderSupers } = getters.getHttps
      const { data } = await getProjectFolderSupers(params)
      return setStoreApiDate(data, commit)
    },
    // 初始化项目目录
    async fetchInitProjectFolder({ commit, getters }, params) {
      const { initProjectFolder } = getters.getHttps
      const { data } = await initProjectFolder(params)
      return setStoreApiDate(data, commit)
    }
  }
}
