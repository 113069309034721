/**
 * 角色管理接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 分页查询角色信息
    async fetchGetSAysRoles({ commit, getters }, params) {
      const { getSAysRoles } = getters.getHttps
      const { data } = await getSAysRoles(params)
      return setStoreApiDate(data, commit)
    },
    // 新增角色信息
    async fetchAddSysRole({ commit, getters }, params) {
      const { addSysRole } = getters.getHttps
      const { data } = await addSysRole(params)
      return setStoreApiDate(data, commit)
    },
    // 修改角色信息
    async fetchUpdateSysRole({ commit, getters }, params) {
      const { updateSysRole } = getters.getHttps
      const { data } = await updateSysRole(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单个角色信息
    async fetchGetSAysRole({ commit, getters }, roleId) {
      const { getSAysRole } = getters.getHttps
      const { data } = await getSAysRole(false, false, {
        url: `${prefix}/v1/op/sysRole/${roleId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 删除单个角色信息
    async fetchDeleteSAysRole({ commit, getters }, roleId) {
      const { deleteSAysRole } = getters.getHttps
      const { data } = await deleteSAysRole(false, false, {
        url: `${prefix}/v1/op/sysRole/${roleId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 删除单个角色信息
    async fetchGetSysModulesByRole({ commit, getters }, params) {
      const { getSysModulesByRole } = getters.getHttps
      const { data } = await getSysModulesByRole(params)
      return setStoreApiDate(data, commit)
    }
  }
}
