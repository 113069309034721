<template>
  <!--
    @name: CustomForm
    @describe: 表单Form
    @author: L
    @date: 2021-05-25
 -->
  <el-form
    @submit.prevent
    :model="model"
    :label-width="labelWidth"
    :label-position="labelPosition"
    :rules="rules"
    :inline="inline"
    ref="CustomFormRef"
  >
    <slot></slot>
  </el-form>
</template>

<script>
import { ref } from 'vue'
export default {
  name: 'CustomForm',
  props: {
    // 绑定数据对象
    model: {
      type: Object
    },
    // 描述payc
    labelWidth: {
      type: String,
      default: ''
    },
    // 描述位置 right, top, left
    labelPosition: {
      type: String,
      default: 'right'
    },
    // 校验对象
    rules: {
      type: Object
    },
    // 是否块级排列
    inline: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    // form el
    const CustomFormRef = ref()
    return { CustomFormRef }
  }
}
</script>

<style></style>
