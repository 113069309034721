import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'
import Home from '../views/home/Home.vue'
import { setMemoryPageFn } from '../utils'

const routes = [
  {
    path: '/',
    name: 'Home',
    // redirect: '/superAdmin',
    meta: {
      // 需要登录
      requiredLogin: true
    },
    component: Home,
    children: [
      // 超级管理员
      {
        path: '/superAdmin',
        name: 'SuperAdminView',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '运维态势'
        },
        component: () => import('@/views/superAdminView/index.vue')
      },
      // 阿米巴
      {
        path: '/amoebaHead',
        name: 'AmoebaHeadView',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '运维态势'
        },
        component: () => import('@/views/amoebaHeadView/index.vue')
      },
      // 单位管理列表
      {
        path: '/unitManage',
        name: 'UnitManage',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '单位管理'
        },
        component: () => import('@/views/unitManage/index.vue')
      },
      // 单位管理动作列表
      {
        path: '/unitAction',
        name: 'UnitAction',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 所在父级
          path: '/unitManage'
        },
        component: () => import('@/views/unitManage/action.vue')
      },
      // 帐户管理列表
      {
        path: '/accountListApp',
        name: 'AccountListApp',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '小程序帐号管理列表'
        },
        component: () => import('@/views/accountListApp/index.vue')
      },
      // 帐户动作列表
      {
        path: '/userAppAction',
        name: 'UserAppAction',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 所在父级
          path: '/accountListApp'
        },
        component: () => import('@/views/accountListApp/action.vue')
      },
      // 帐户管理列表
      {
        path: '/accountList',
        name: 'AccountList',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '后台帐号管理列表'
        },
        component: () => import('@/views/accountList/index.vue')
      },
      // 帐户动作列表
      {
        path: '/userAction',
        name: 'UserAction',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 所在父级
          path: '/accountList'
        },
        component: () => import('@/views/accountList/action.vue')
      },
      // 角色管理
      {
        path: '/roleManage',
        name: 'RroleManage',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '角色管理'
        },
        component: () => import('@/views/roleManage/index.vue')
      },
      // 角色动作列表
      {
        path: '/roleManageAction',
        name: 'RoleManageAction',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 所在父级
          path: '/roleManage'
        },
        component: () => import('@/views/roleManage/action.vue')
      },
      // 运维报告
      {
        path: '/opsReport',
        name: 'opsReport',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '运维报告'
        },
        component: () => import('@/views/opsReport/index.vue')
      },
      // 生成运维报告
      {
        path: '/opsReportAction',
        name: 'opsReportAction',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 所在父级
          path: '/opsReport'
        },
        component: () => import('@/views/opsReport/action.vue')
      },
      // 项目信息管理
      {
        path: '/projectInfoManage',
        name: 'ProjectInfoManage',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '项目信息管理'
        },
        component: () => import('@/views/projectInfoManage/index.vue')
      },
      // 项目信息管理动作列表
      {
        path: '/projectInfoManageAction',
        name: 'ProjectInfoManageAction',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 所在父级
          path: '/projectInfoManage'
        },
        component: () => import('@/views/projectInfoManage/action.vue')
      },
      // 日志管理列表
      {
        path: '/logManage',
        name: 'LogManage',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '操作日志'
        },
        component: () => import('@/views/logManage/index.vue')
      },
      // 设备管理列表
      {
        path: '/equipmentManage',
        name: 'EquipmentManage',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '设备管理'
        },
        component: () => import('@/views/equipmentManage/index.vue')
      },
      // 设备监控
      {
        path: '/equipmentMMonitor',
        name: 'EquipmentMMonitor',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 所在父级
          path: '/equipmentManage'
        },
        component: () => import('@/views/equipmentManage/monitor.vue')
      },
      // 设备动作列表
      {
        path: '/equipmentMAction',
        name: 'EquipmentMAction',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 所在父级
          path: '/equipmentManage'
        },
        component: () => import('@/views/equipmentManage/action.vue')
      },
      // 备品备件仓库
      {
        path: '/spareParts',
        name: 'SpareParts',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '备品备件仓库'
        },
        component: () => import('@/views/spareParts/index.vue')
      },
      // 设备动作列表
      {
        path: '/sparePartsMAction',
        name: 'SparePartsAction',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 所在父级
          path: '/spareParts'
        },
        component: () => import('@/views/spareParts/action.vue')
      },
      // 入库记录
      {
        path: '/sparePutRecord',
        name: 'SparePutRecord',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '入库记录'
        },
        component: () => import('@/views/sparePutRecord/index.vue')
      },
      // 入库记录详情
      {
        path: '/sparePutRordAction',
        name: 'sparePutRordAction',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '入库记录详情'
        },
        component: () => import('@/views/sparePutRecord/action.vue')
      },
      // 出库记录
      {
        path: '/spareOutRecord',
        name: 'SpareOutRecord',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '出库记录'
        },
        component: () => import('@/views/spareOutRecord/index.vue')
      },
      // 出库记录
      {
        path: '/spareOutRecordAction',
        name: 'spareOutRecordAction',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '出库记录详情'
        },
        component: () => import('@/views/spareOutRecord/action.vue')
      },
      // 故障原始记录
      {
        path: '/faultOriginalRecord',
        name: 'faultOriginalRecord',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '故障原始记录'
        },
        component: () => import('@/views/faultOriginalRecord/index.vue')
      },
      // 故障记录
      {
        path: '/faultRecord',
        name: 'FaultRecord',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '故障记录'
        },
        component: () => import('@/views/faultRecord/index.vue')
      },
      // 故障业务中心
      {
        path: '/faultServiceCenter',
        name: 'FaultServiceCenter',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '故障业务中心'
        },
        component: () => import('@/views/faultServiceCenter/index.vue')
      },
      // 故障业务中心动作
      {
        path: '/faultServiceCenterAction',
        name: 'FaultServiceCenterAction',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 所在父级
          path: '/faultServiceCenter'
        },
        component: () => import('@/views/faultServiceCenter/action.vue')
      },
      // 故障业务中心动作
      {
        path: '/fultServiceCenterActionReSubmit',
        name: 'FultServiceCenterActionReSubmit',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 所在父级
          path: '/faultServiceCenter'
        },
        component: () => import('@/views/faultServiceCenter/actionReSubmit.vue')
      },
      // 故障业务中心详情
      {
        path: '/faultServiceCenterDetails',
        name: 'FaultServiceCenterDetails',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 所在父级
          path: '/faultServiceCenter'
        },
        component: () => import('@/views/faultServiceCenter/details.vue')
      },
      // 设备图层
      // {
      //   path: '/equipmentLayer',
      //   name: 'EquipmentLayer',
      //   meta: {
      //     // 需要登录
      //     requiredLogin: true,
      //     // 是否可添到加快捷列表
      //     quick: true,
      //     // 快捷名称
      //     title: '设备图层'
      //   },
      //   component: () => import('@/views/equipmentLayer/index.vue')
      // },
      // 流程管理
      {
        path: '/processManage',
        name: 'ProcessManage',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '流程管理'
        },
        component: () => import('@/views/processManage/index.vue')
      },
      // 流程管理
      {
        path: '/processManageAction',
        name: 'ProcessManageAction',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 所在父级
          path: '/processManage'
        },
        component: () => import('@/views/processManage/action.vue')
      },
      // 巡检计划
      {
        path: '/inspectPlan',
        name: 'InspectPlan',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '巡检计划'
        },
        component: () => import('@/views/inspectPlan/index.vue')
      },
      // 巡检计划动作页面
      {
        path: '/inspectPlanAction',
        name: 'InspectPlanAction',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 所在父级
          path: '/inspectPlan'
        },
        component: () => import('@/views/inspectPlan/action.vue')
      },
      // 巡检任务
      {
        path: '/inspectTasks',
        name: 'InspectTasks',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '巡检任务'
        },
        component: () => import('@/views/inspectTasks/index.vue')
      },
      // 巡检任务动作页面
      {
        path: '/inspectTasksAction',
        name: 'InspectTasksAction',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 所在父级
          path: '/inspectTasks'
        },
        component: () => import('@/views/inspectTasks/action.vue')
      },
      // 巡检任务动作页面
      {
        path: '/inspectTasksDetails',
        name: 'InspectTasksDetails',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 所在父级
          path: '/inspectTasks'
        },
        component: () => import('@/views/inspectTasks/details.vue')
      },
      // 文件资料管理
      {
        path: '/projectFolders',
        name: 'ProjectFolders',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '文件资料管理'
        },
        component: () => import('@/views/projectFolders/index.vue')
      },
      {
        path: '/knowledgeBase',
        name: 'knowledgeBase',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '知识库管理'
        },
        component: () => import('@/views/knowledgeBase/index.vue')
      },
      // 投诉建议记录
      {
        path: '/suggestRecords',
        name: 'SuggestRecords',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '投诉建议记录'
        },
        component: () => import('@/views/suggestRecords/index.vue')
      },
      // 值班管理
      {
        path: '/dutyInfosManage',
        name: 'DutyInfosManage',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '值班管理'
        },
        component: () => import('@/views/dutyInfosManage/index.vue')
      },
      // 值班动作页面
      {
        path: '/dutyInfosManageAction',
        name: 'DutyInfosManageAction',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 所在父级
          path: '/dutyInfosManage'
        },
        component: () => import('@/views/dutyInfosManage/action.vue')
      },
      // 值班详情页面
      {
        path: '/dutyInfosManagetDetails',
        name: 'DutyInfosManagetDetails',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 所在父级
          path: '/dutyInfosManage'
        },
        component: () => import('@/views/dutyInfosManage/details.vue')
      },
      // // 值班日志
      // {
      //   path: '/dutyLog',
      //   name: 'DutyLog',
      //   meta: {
      //     // 需要登录
      //     requiredLogin: true,
      //     // 是否可添到加快捷列表
      //     quick: true,
      //     // 快捷名称
      //     title: '值班日志'
      //   },
      //   component: () => import('@/views/dutyLog/index.vue')
      // },
      // 值班日志动作页面
      {
        path: '/dutyLogAction',
        name: 'DutyLogAction',
        meta: {
          // 需要登录
          requiredLogin: true,
          quick: true,
          //     // 快捷名称
          title: '值班日志'
        },
        component: () => import('@/views/dutyLog/action.vue')
      },
      {
        path: '/workLogReport',
        name: 'WorkLogReport',
        meta: {
          // 需要登录
          requiredLogin: true,
          quick: true,
          //     // 快捷名称
          title: '日志填报'
        },
        component: () => import('@/views/workLogReport/index.vue')
      },
      // 通知通告发布
      {
        path: '/companyNotices',
        name: 'CompanyNotices',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '通知通告发布'
        },
        component: () => import('@/views/companyNotices/index.vue')
      },
      // 满意度登记记录
      {
        path: '/satisfactionRecords',
        name: 'SatisfactionRecords',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '满意度登记记录'
        },
        component: () => import('@/views/satisfactionRecords/index.vue')
      },
      // 满意度登记记录动作页面
      {
        path: '/satisfactionRecordsAction',
        name: 'SatisfactionRecordsAction',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 所在父级
          path: '/satisfactionRecords'
        },
        component: () => import('@/views/satisfactionRecords/action.vue')
      },
      // 项目图层
      // {
      //   path: '/projectLayer',
      //   name: 'ProjectLayer',
      //   meta: {
      //     // 需要登录
      //     requiredLogin: true,
      //     // 是否可添到加快捷列表
      //     quick: true,
      //     // 快捷名称
      //     title: '项目图层'
      //   },
      //   component: () => import('@/views/projectLayer/index.vue')
      // },
      // 监视图层
      {
        path: '/monitoringLayer',
        name: 'MonitoringLayer',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '综合监视'
        },
        component: () => import('@/views/monitoringLayer/index.vue')
      },
      {
        path: '/projectLayer',
        name: 'projectLayer',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '项目一张图'
        },
        component: () => import('@/views/monitoringLayer/projectLayer.vue')
      },
      {
        path: '/deviceLayer',
        name: 'deviceLayer',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '设备一张图'
        },
        component: () => import('@/views/monitoringLayer/deviceLayer.vue')
      },
      // 短信记录
      {
        path: '/smsSendRecords',
        name: 'SmsSendRecords',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '短信记录'
        },
        component: () => import('@/views/smsSendRecords/index.vue')
      },
      // 短信模版
      {
        path: '/smsTemplates',
        name: 'SmsTemplates',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '短信模版'
        },
        component: () => import('@/views/smsTemplates/index.vue')
      },
      // 事件发送记录
      {
        path: '/eventSendRecords',
        name: 'EventSendRecords',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '事件发送记录'
        },
        component: () => import('@/views/eventSendRecords/index.vue')
      },
      // 预警信号记录
      {
        path: '/issueSigal',
        name: 'EssueSigal',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '预警信号记录'
        },
        component: () => import('@/views/issueSigal/index.vue')
      },
      // 短信模版
      {
        path: '/eventTemplates',
        name: 'EventTemplates',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '事件模版'
        },
        component: () => import('@/views/eventTemplates/index.vue')
      },
      // 季度收益登记
      {
        path: '/quarterlyEarnings',
        name: 'QuarterlyEarnings',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '季度收益记录'
        },
        component: () => import('@/views/quarterlyEarnings/index.vue')
      },
      // 日志统计
      {
        path: '/logStatistics',
        name: 'LogStatistics',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '日志统计'
        },
        component: () => import('@/views/logStatistics/index.vue')
      },
      // 季度收益登记
      {
        path: '/quarterlyEarning',
        name: 'QuarterlyEarning',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '季度收益登记'
        },
        component: () => import('@/views/quarterlyEarning/index.vue')
      },
      // 季度收益登记动作页面
      {
        path: '/quarterlyEarningAction',
        name: 'QuarterlyEarningAction',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 所在父级
          path: '/quarterlyEarning'
        },
        component: () => import('@/views/quarterlyEarning/action.vue')
      },
      // 巡检一张图
      {
        path: '/inspectLayer',
        name: 'InspectLayer',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '巡检一张图'
        },
        component: () => import('@/views/inspectLayer/index.vue')
      },
      // 外链
      {
        path: '/spLayer',
        name: 'spLayer',
        meta: {
          // 需要登录
          requiredLogin: true,
          // 是否可添到加快捷列表
          quick: true,
          // 快捷名称
          title: '视频监控'
        },
        component: () => import('@/views/spLayer/index.vue')
      }
    ]
  },
  // 登陆 页面
  {
    path: '/login',
    name: 'Login',
    meta: {
      // 检测是否有token,会自动登陆
      redirectAlreadyLogin: true
    },
    component: () => import('@/views/login/index.vue')
  },
  // 404 页面
  {
    path: '/404',
    name: 'NotFile',
    meta: {
      // 检测是否有token,会自动登陆
      redirectAlreadyLogin: true
    },
    component: () => import('@/components/404.vue')
  },
  // openApp 页面
  {
    path: '/openApp',
    name: 'OpenApp',
    meta: {
      // 检测是否有token,会自动登陆
      // redirectAlreadyLogin: true
    },
    component: () => import('@/components/openApp.vue')
  },
  // openApp 页面
  {
    path: '/openAppHistory',
    name: 'openAppHistory',
    meta: {
      // 检测是否有token,会自动登陆
      // redirectAlreadyLogin: true
    },
    component: () => import('@/views/openAppHistory/index.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  // next()
  const { commit, getters, dispatch } = store
  const { requiredLogin, redirectAlreadyLogin } = to.meta
  const { authorization } = to.query
  commit('setIsRefresh', true)
  if (['/superAdmin', '/amoebaHead'].includes(to.path)) {
    commit('setQuickBg', true)
  } else {
    commit('setQuickBg', false)
  }
  // 重置页面保存
  if (
    !(
      to.path === from.meta.path ||
      from.path === to.meta.path ||
      ['/faultServiceCenterDetails'].includes(from.path) ||
      ['/faultServiceCenter'].includes(to.meta.path)
    )
  ) {
    setMemoryPageFn(commit)
  }
  if (to.matched.length === 0) {
    const fromPath = from.path || '/'
    next({ name: 'NotFile', params: { parch: fromPath } })
  } else {
    if (!getters.getLogin) {
      if (authorization) {
        commit('setToken', authorization)
      }
      if (getters.getToken && getters.getToken !== '') {
        await dispatch('fetchGetCurrentUser')
          .then(data => {
            if (data && data.code === 200 && data.data) {
              commit('setUser', { ...data.data, isLogin: true })
              if (redirectAlreadyLogin) {
                next({ path: '/' })
              } else {
                next()
              }
            } else {
              commit('setLoginOut')
              // next({ path: '/login' })
            }
          })
          .catch(() => {
            commit('setLoginOut')
            // next({ path: '/login' })
          })
      } else {
        if (requiredLogin) {
          next({ path: '/login' })
        } else {
          next()
        }
      }
    } else {
      next()
    }
  }
})

router.afterEach(async to => {
  const { commit } = store
  commit('setIsRefresh', false)
  if (to.matched.length > 0) {
    if (to.meta.quick) {
      commit('setRouterList', { data: to })
    } else if (to.meta.path) {
      to.matched[0].children.map(item => {
        if (item.path === to.meta.path) {
          commit('setRouterList', { data: item })
        }
      })
    }
  }
})

export default router
