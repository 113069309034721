/**
 * 事件管理接口
 */

const prefix = '/dseWeb'
export default [
  // 分页查询事件短信发送记录
  { name: 'getEventSendRecords', url: `${prefix}/v1/op/eventSendRecords`, method: 'get' },
  // 查询单个事件短信发送记录
  { name: 'getEventSendRecord', url: `${prefix}/v1/op/eventSendRecord`, method: 'get' },
  // 分页查询事件模板
  { name: 'getEventTemplates', url: `${prefix}/v1/op/eventTemplates`, method: 'get' },
  // 新增事件模板
  { name: 'addEventTemplate', url: `${prefix}/v1/op/eventTemplate`, method: 'post' },
  // 修改事件模板
  { name: 'updateEventTemplate', url: `${prefix}/v1/op/eventTemplate`, method: 'put' },
  // 查询单个事件模板
  { name: 'getEventTemplate', url: `${prefix}/v1/op/eventTemplate`, method: 'get' },
  // 删除事件模板
  { name: 'deleteEventTemplate', url: `${prefix}/v1/op/eventTemplate`, method: 'delete' },
  // 分页查询事件数据记录
  { name: 'getEventDataRecords', url: `${prefix}/v1/op/eventDataRecords`, method: 'get' },
  // 查询预警事件是否自动发送
  { name: 'getEventAlarmStatus', url: `${prefix}/v1/op/eventAlarmStatus`, method: 'get' },
  // 修改预警事件是否自动发送状态
  { name: 'updateEventAlarmStatus', url: `${prefix}/v1/op/eventAlarmStatus`, method: 'put' },
  // 手动发送事件数据记录
  { name: 'eventDataRecordSend', url: `${prefix}/v1/op/eventDataRecordSend`, method: 'post' }
]
