<template>
  <div style="width: 100%; height: 100%" :ref="el => (echartsIdRefs[id] = el)"></div>
</template>

<script>
import { onMounted, onUnmounted, ref, watch, markRaw } from 'vue'
import * as echarts from 'echarts'

export default {
  name: 'EchartsView',
  props: {
    id: String,
    option: Object
  },
  setup(props, content) {
    const echartsIdRefs = ref({})
    const echartsRef = ref()
    // const nInterval = ref()
    const initEchartsFs = () => {
      // clearInterval(nInterval.value)
      // .setOption(props.option, true)
      // echartsRef.value = echarts.getInstanceByDom(props.id)
      // if (echartsRef.value == null) {
      //   echartsRef.value = echarts.init(echartsIdRefs.value[props.id])
      // }
      // const { dataZoom, series } = props.option
      // if (dataZoom && dataZoom.length > 0) {
      //   if (!nInterval.value) {
      //     nInterval.value = setInterval(() => {
      //       if (dataZoom[0].end === (series[0].data.length - 1) * 20) {
      //         dataZoom[0].start = 0
      //         dataZoom[0].end = 20
      //       } else {
      //         dataZoom[0].start = dataZoom[0].start + 10
      //         dataZoom[0].end = dataZoom[0].end + 10
      //       }
      //       echartsRef.value.setOption({ ...props.option, dataZoom })
      //     }, 2000)
      //   }
      // } else {
      //   echartsRef.value.setOption({ ...props.option }, true)
      // }
      setTimeout(() => {
        try {
          echartsRef.value.setOption({ ...props.option }, true)
        } catch (error) {
          console.log(error)
        }
      }, 500)
    }
    // const option = {
    //   title: {
    //     text: 'ECharts 入门示例'
    //   },
    //   tooltip: {},
    //   legend: {
    //     data: ['销量']
    //   },
    //   xAxis: {
    //     data: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子']
    //   },
    //   yAxis: {},
    //   series: [
    //     {
    //       name: '销量',
    //       type: 'bar',
    //       data: [5, 20, 36, 10, 10, 20]
    //     }
    //   ]
    // }
    watch(
      () => props.option,
      () => {
        initEchartsFs()
      }
    )
    onMounted(() => {
      echartsRef.value = echarts.getInstanceByDom(props.id)
      if (echartsRef.value == null) {
        echartsRef.value = markRaw(echarts.init(echartsIdRefs.value[props.id]))
      }
      echartsRef.value.on('click', params => {
        content.emit('click-map', params)
      })
      // window.addEventListener('resize', initEchartsFs)
      initEchartsFs()
    })

    onUnmounted(() => {
      // window.removeEventListener('resize', initEchartsFs)
      // clearInterval(nInterval.value)
    })
    return { echartsIdRefs }
  }
}
</script>

<style lang="scss"></style>
