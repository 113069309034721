/**
 * 入库记录接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 分页查询备品入库记录
    async fetchGetSparePutRecords({ commit, getters }, params) {
      const { getSparePutRecords } = getters.getHttps
      const { data } = await getSparePutRecords(params)
      return setStoreApiDate(data, commit)
    },
    // 新增备品入库记录
    async fetchAddSparePutRecord({ commit, getters }, params) {
      const { addSparePutRecord } = getters.getHttps
      const { data } = await addSparePutRecord(params)
      return setStoreApiDate(data, commit)
    },
    // 修改备品入库记录
    async fetchUpdataSparePutRecord({ commit, getters }, params) {
      const { updataSparePutRecord } = getters.getHttps
      const { data } = await updataSparePutRecord(params)
      return setStoreApiDate(data, commit)
    },
    // 删除备品入库记录
    async fetchsparePutRecord({ commit, getters }, recordId) {
      const { sparePutRecord } = getters.getHttps
      const { data } = await sparePutRecord(false, false, {
        url: `${prefix}/v1/op/sparePutRecord/${recordId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 入库记录详情
    async fetchGetSparePutRecord({ commit, getters }, recordId) {
      const { getSparePutRecord } = getters.getHttps
      const { data } = await getSparePutRecord(false, false, {
        url: `${prefix}/v1/op/sparePutRecord/${recordId}`
      })
      return setStoreApiDate(data, commit)
    }
  }
}
