/**
 * 通知通告发布接口
 */

const prefix = '/dseWeb'
export default [
  // 分页查询通知通告发布
  { name: 'getCompanyNotices', url: `${prefix}/v1/op/companyNotices`, method: 'get' },
  // 查询单个通知通告发布
  { name: 'getCompanyNotice', url: `${prefix}/v1/op/companyNotice`, method: 'get' },
  // 新增通知通告发布
  { name: 'addCompanyNotice', url: `${prefix}/v1/op/companyNotice`, method: 'post' },
  // 修改通知通告发布
  { name: 'updateCompanyNotice', url: `${prefix}/v1/op/companyNotice`, method: 'put' },
  // 删除通知通告发布
  { name: 'deleteCompanyNotice', url: `${prefix}/v1/op/companyNotice`, method: 'delete' }
]
