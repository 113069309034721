/**
 * 后台用户接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 查询登录后台账号
    async fetchGetCurrentUser({ commit, getters }) {
      const { getCurrentUser } = getters.getHttps
      const { data } = await getCurrentUser()
      return setStoreApiDate(data, commit)
    },
    // 修改密码
    async fetchUpdateUserPass({ commit, getters }, params) {
      const { updateUserPass } = getters.getHttps
      const { data } = await updateUserPass(params)
      return setStoreApiDate(data, commit)
    },
    // 分页查询后台用户
    async fetchGetSysUsers({ commit, getters }, params) {
      const { getSysUsers } = getters.getHttps
      const { data } = await getSysUsers(params)
      return setStoreApiDate(data, commit)
    },
    // 查询阿米巴单位下所有用户
    async fetchGetAmoebaBelowSysUsers({ commit, getters }, id) {
      const { getAmoebaBelowSysUsers } = getters.getHttps
      const { data } = await getAmoebaBelowSysUsers(false,false,{
        url: `${prefix}/v1/op/amoebaBelowSysUsers/${id}`
      })
      return setStoreApiDate(data, commit)
    },
    // 新增后台用户
    async fetchAddSysUser({ commit, getters }, params) {
      const { addSysUser } = getters.getHttps
      const { data } = await addSysUser(params)
      return setStoreApiDate(data, commit)
    },
    // 修改后台用户
    async fetchUpdateSysUser({ commit, getters }, params) {
      const { updateSysUser } = getters.getHttps
      const { data } = await updateSysUser(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单个后台用户
    async fetchGetSysUser({ commit, getters }, userId) {
      const { getSysUser } = getters.getHttps
      const { data } = await getSysUser(false, false, {
        url: `${prefix}/v1/op/sysUser/${userId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 删除后台用户
    async fetchDeleteSysUser({ commit, getters }, userId) {
      const { deleteSysUser } = getters.getHttps
      const { data } = await deleteSysUser(false, false, {
        url: `${prefix}/v1/op/sysUser/${userId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 重置密码
    async fetchResetUserPass({ commit, getters }, userId) {
      const { resetUserPass } = getters.getHttps
      const { data } = await resetUserPass(false, false, {
        url: `${prefix}/v1/op/resetUserPass/${userId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 分页查询系统日志信息
    async fetchSysLogInfos({ commit, getters }, params) {
      const { sysLogInfos } = getters.getHttps
      const { data } = await sysLogInfos(params)
      return setStoreApiDate(data, commit)
    }
  }
}
