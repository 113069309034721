<template>
  <el-card
    ref="cardRef"
    :shadow="shadow"
    class="count-card"
    :class="{ 'horizontal-card': mode === 'horizontal' }"
    :style="{ 'background-color': backgroundColor, height: height, width: width, border: border }"
    :body-style="{ color: color }"
    @click="clickCard"
  >
    <div :style="{ 'font-weight': 'bold', 'font-size': size, color: valueColor }">
      <span v-if="isPrice">￥</span>
      {{ isPrice ? price.toFixed(2) : price }}
      <span v-if="unit" style="font-size: 10px; margin-left: 5px">{{ unit }}</span>
    </div>
    <div v-if="label" :style="{ 'font-size': labelSize }">{{ label }}</div>
  </el-card>
</template>

<script>
import { ref } from '@vue/reactivity'
export default {
  name: 'CountCard',
  props: {
    isPrice: {
      type: Boolean,
      default: true
    },
    border: {
      type: [Boolean, Number],
      default: true
    },
    // 是否显示阴影 always 总显示， hover 鼠标触发显示， never 总不显示
    shadow: {
      type: String,
      default: 'always'
    },
    unit: {
      type: String,
      default: '元'
    },
    height: {
      type: String,
      default: '99px'
    },
    width: {
      type: String
    },
    price: {
      type: [Number, String],
      default: 0
    },
    label: {
      type: String,
      default: ''
    },
    labelSize: {
      type: String,
      default: '20px'
    },
    color: {
      type: String,
      default: '#fff'
    },
    backgroundColor: {
      type: String,
      default: '#58a3f7'
    },
    valueColor: {
      type: String,
      default: '#fff'
    },
    size: {
      type: String,
      default: '28px'
    },
    mode: {
      // horizontal vertical
      type: String,
      default: 'vertical'
    }
  },
  setup(props, context) {
    const cardRef = ref()
    const clickCard = () => {
      context.emit('click-card')
    }
    return { cardRef, clickCard }
  }
}
</script>

<style lang="scss">
.horizontal-card {
  .el-card__body {
    flex-direction: row-reverse !important;
    justify-content: space-around !important;
    align-items: center;
  }
}
.count-card {
  color: #fff;
  text-align: center;
  border-radius: 10px;
  flex: 1;
  margin: 0 10px;
  min-width: 184px;
  .el-card__body {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    box-sizing: border-box;
  }
}
</style>
