<template>
  <div class="home">
    <!-- 主框架 -->
    <el-container style="height: 100%">
      <!-- 左侧模块 -->
      <el-aside
        style="width: 210px"
        height="100%"
        :class="{
          'el-aside-collapse': showUserInfoText,
          'el-aside-no-collapse': !showUserInfoText
        }"
      >
        <menu-left-user-info :show="showUserInfoText" />
        <el-scrollbar class="scrollbarLeftHeight">
          <menu-left
            v-if="menuLeftData.length > 0"
            ref="menuLeftRef"
            :list="menuLeftData"
            :activePath="activePath"
          />
        </el-scrollbar>
      </el-aside>
      <!-- 主体框架 -->
      <el-container style="height: 100%">
        <!-- 头部模块 -->
        <el-header>
          <nav-top
            @click-login-out="loginOut"
            @click-reset-pwd="resetpwd"
            @click-fold-icon="clickFoldIcon"
            @click-meteorological-service="meteorologicalService"
            :name="userInfo.userName"
            :unitName="userInfo.companyName"
            :logo="userInfo.logo"
            :userId="userInfo.userId"
            :foldIcon="showUserInfoText ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
          />
        </el-header>
        <!-- 主内容区域 -->
        <el-main ref="elMainRef" width="100%" height="50%" :class="{ mainFull: isFull }">
          <el-header
            class="router-tag-box"
            :style="quickBg ? { background: '#0a263e', color: '#fff' } : {}"
          >
            <router-tag
              @delete-router-list="deleteRouterList"
              @click-full-main="clickFullMain"
              @close-tag="closeTag"
              :list="routerList"
            />
          </el-header>
          <!-- 背景粒子 -->
          <vue-particles
            color="#dedede"
            :particleOpacity="0.7"
            :particlesNumber="80"
            shapeType="circle"
            :particleSize="4"
            linesColor="#dedede"
            :linesWidth="1"
            :lineLinked="true"
            :lineOpacity="0.4"
            :linesDistance="150"
            :moveSpeed="3"
            :hoverEffect="true"
            hoverMode="grab"
            :clickEffect="true"
            clickMode="push"
            style="height: 100%; width: 100%; position: absolute; left: 0; top: 0; z-index: 0"
          />
          <el-scrollbar class="scrollbarHeight" ref="scrollbarRef">
            <router-view v-slot="{ Component }">
              <transition name="fade-transform" mode="out-in">
                <keep-alive include="Home,News">
                  <div v-if="!isRefresh" :include="cachePages" style="padding: 0; height: 100%">
                    <component class="view" :is="Component" />
                  </div>
                </keep-alive>
              </transition>
            </router-view>
          </el-scrollbar>
        </el-main>
      </el-container>
    </el-container>
    <!-- 退出登录提示框 -->
    <ml-dialog ref="loginOutDialogRef" content="确定退出登录？" @click-submit="submitLoginOut" />
    <!-- 修改密码对话 框 -->
    <ml-dialog
      width="455px"
      title="修改密码"
      ref="resetPwdDialogRef"
      @click-submit="submitResetPwd"
    >
      <template #body>
        <!-- 修改密码表单 -->
        <ml-form
          ref="updateUserPwdRef"
          style="width: 80%"
          labelWidth="100px"
          :model="resetPwdFormData"
          :rules="resetPwdRules"
        >
          <!-- 输入框-原密码 -->
          <ml-input
            prop="password"
            label="原密码"
            prefixIcon="el-icon-unlock"
            placeholder="请输入原密码"
            v-model="resetPwdFormData.password"
            showPassword
          />
          <!-- 输入框-新密码 -->
          <ml-input
            prop="newUserPass"
            label="新密码"
            prefixIcon="el-icon-unlock"
            placeholder="请输入新密码"
            v-model="resetPwdFormData.newUserPass"
            showPassword
          />
          <!-- 输入框-确认新密码 -->
          <ml-input
            prop="againNewPwd"
            label="确认新密码"
            prefixIcon="el-icon-unlock"
            placeholder="请输入确认新密码"
            v-model="resetPwdFormData.againNewPwd"
            showPassword
            style="margin-bottom: 0"
          />
        </ml-form>
      </template>
    </ml-dialog>
  </div>
</template>

<script>
import { computed, onMounted, provide, reactive, ref, watch } from 'vue'
import NavTop from './components/NavTop.vue'
import MenuLeft from './components/MenuLeft.vue'
import MenuLeftUserInfo from './components/MuneLeftUserInfo.vue'
import RouterTag from './components/RouterTag.vue'
// import menuLeftData from './menuLeftData'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { BASEURL, prefix } from '@API'

export default {
  name: 'Home',
  components: {
    NavTop,
    MenuLeft,
    MenuLeftUserInfo,
    RouterTag
  },
  setup() {
    const { commit, getters, dispatch } = useStore()
    const router = useRouter()
    const route = useRoute()
    const updateUserPwdRef = ref()
    const scrollbarRef = ref()
    // 设置缓存组件列表
    const cachePages = computed(() => getters.getCachePages)
    const isRefresh = computed(() => getters.getIsRefresh)
    const routerKey = computed(() => route.path)
    const routerList = computed(() => getters.getRouterList)
    const toKen = computed(() => getters.getToken)
    const quickBg = computed(() => getters.getQuickBg)
    const iframeSrc = ref(
      `${BASEURL}${prefix}/v1/meteorological?token=${encodeURIComponent(toKen.value)}`
    )
    // 左边菜单选中
    const activePath = ref(route.meta && route.meta.path ? route.meta.path : route.path)
    const goPath = newvalue => {
      if (newvalue && newvalue.length > 0) {
        const hrefArr = []
        newvalue.map(item => {
          if (item.href) {
            hrefArr.push(item.href)
          }

          if (item.children.length > 0) {
            item.children.map(v => {
              if (v.href) {
                hrefArr.push(v.href)
              }
            })
          }
        })
        if (hrefArr.length > 0) {
          if (routerList.value.length <= 0) {
            router.push({ path: hrefArr[0] })
          }
        } else {
          router.push({ path: '/404' })
        }
        // const { href, children } = newvalue[0]
        // if (href) {
        //   router.push({ path: href })
        // } else if (children && children.length > 0) {
        //   const { href } = children[0]
        //   router.push({ path: href })
        // } else {
        //   router.push({ path: '/404' })
        // }
      } else {
        router.push({ path: '/404' })
      }
    }
    watch(
      () => route.path,
      newvalue => {
        if (newvalue === '/') {
          goPath(menuLeftData.value)
        } else {
          activePath.value = route.meta.path ? route.meta.path : newvalue
        }
      }
    )
    // 获取用户信息
    const userInfo = computed(() => getters.getUser)
    // 菜单
    const menuLeftData = computed(() => getters.getMenuList)
    watch(
      () => menuLeftData.value,
      newvalue => {
        goPath(newvalue)
      }
    )
    /*
     * 退出登录
     */
    // 登出对话框Dom
    const loginOutDialogRef = ref()
    // 显示登出对话框
    const loginOut = () => {
      loginOutDialogRef.value.showDialog = true
    }
    const showUserInfoText = ref(false)
    // 收缩展开左边菜单
    const menuLeftRef = ref()
    const clickFoldIcon = () => {
      showUserInfoText.value = menuLeftRef.value.isCollapse = !menuLeftRef.value.isCollapse
    }
    const meteorologicalService = () => {
      window.open(iframeSrc.value)
    }
    // 确定登出--跳转登录页面
    const submitLoginOut = () => {
      dispatch('fetchLoginOut').then(data => {
        if (data && data.code === 200) {
          // 清除用户信息
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在前往重新登录...',
            type: 'success'
          })
          commit('setLoginOut')
          // router.push({ path: '/login' })
        }
      })
    }
    /*
     * 修改密码
     */
    // 修改密码对话框Dom
    const resetPwdDialogRef = ref()
    // 修改密码表单配置
    const resetPwdFormData = reactive({
      password: '',
      newUserPass: '',
      againNewPwd: ''
    })
    // 显示修改密码对话框
    const resetpwd = () => {
      resetPwdDialogRef.value.showDialog = true
    }
    // 校验新密码与确认密码是否一致
    const newPwdIsSameAgainPwd = () => {
      return (
        resetPwdFormData.newUserPass &&
        resetPwdFormData.againNewPwd &&
        resetPwdFormData.newUserPass === resetPwdFormData.againNewPwd
      )
    }
    // 修改密码校验
    const resetPwdRules = {
      password: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
      newUserPass: [
        { required: true, message: '请输入新密码', trigger: 'blur' },
        { min: 6, max: 16, message: '请输入6-16位新密码', trigger: 'blur' },
        { validator: newPwdIsSameAgainPwd, message: '新密码与确认新密码不一致', trigger: 'blur' }
      ],
      againNewPwd: [
        { required: true, message: '请输入确认新密码', trigger: 'blur' },
        { min: 6, max: 16, message: '请输入6-16位确认新密码', trigger: 'blur' },
        { validator: newPwdIsSameAgainPwd, message: '新密码与确认新密码不一致', trigger: 'blur' }
      ]
    }
    // 确认修改密码
    const submitResetPwd = () => {
      updateUserPwdRef.value.CustomFormRef.validate()
        .then(() => {
          const { password, newUserPass } = resetPwdFormData
          dispatch('fetchUpdateUserPass', { password, newUserPass }).then(data => {
            // 确定修改密码
            if (data && data.code === 200) {
              commit('setError', {
                status: true,
                title: data.message,
                message: '正在前往重新登录...',
                type: 'success'
              })
              // 清除用户信息
              commit('setLoginOut')
              router.push({ path: '/login' })
            }
          })

          // console.log(true)
        })
        .catch(() => {})
    }

    // 全屏
    const isFull = ref(false)
    const clickFullMain = () => {
      if (isFull.value) {
        isFull.value = false
      } else {
        isFull.value = true
      }
    }
    // 关闭快捷路由
    const closeTag = async index => {
      if (index >= 0 && route.name === routerList.value[index].name) {
        let path = ''
        if (routerList.value[index + 1]) {
          path = routerList.value[index + 1].path
        } else {
          path = routerList.value[index - 1].path
        }
        await router.push(path)
      }
      await commit('setRouterList', {
        data: { name: routerList.value[index].name },
        isRemove: true
      })
    }
    // 清除所有路由快捷
    const deleteRouterList = () => {
      const oneData = routerList.value.filter(item => item.path === route.path)
      commit('setRouterListAll', oneData)
    }
    provide('isFull', isFull)

    provide('scrollbar-ref', () => scrollbarRef.value)
    onMounted(async () => {
      await dispatch('fetchGetUserModuleTreeList', { parentId: 0 }).then(data => {
        if (data && data.code === 200) {
          const superAdmin = {
            title: ' 运维态势',
            field: 'super_admin',
            icon: 'el-icon-s-grid',
            href: '/superAdmin',
            children: []
          }
          const amoebaHead = {
            title: ' 运维态势',
            field: 'super_admin',
            icon: 'el-icon-s-grid',
            href: '/amoebaHead',
            children: []
          }

          const nData = data.data.map(item => {
            if (item.href === '/superAdmin') {
              if (userInfo.value.userType === 'division_manager') {
                return superAdmin
              } else {
                return amoebaHead
              }
            } else {
              return item
            }
          })
          commit('setMenuLeft', [...nData])
          if (route.path === '/' && data.data[0].children && data.data[0].children.length > 0) {
            router.push({ path: data.data[0].children[0].href })
            activePath.value = data.data[0].children[0].href
          } else {
            activePath.value = route.meta.path || route.path
          }
        }
      })
      await dispatch('fetchGetCityAreaTrees').then(data => {
        if (data && data.code === 200) {
          const nData = data.data || []
          if (nData.length > 0) {
            // commit('setArea', [{ code: 0, name: '全部', children: nData }])
            commit('setArea', nData)
          }
        }
      })
    })
    return {
      updateUserPwdRef,
      activePath,
      userInfo,
      loginOutDialogRef,
      loginOut,
      submitLoginOut,
      menuLeftData,
      resetPwdDialogRef,
      resetpwd,
      submitResetPwd,
      resetPwdFormData,
      resetPwdRules,
      clickFoldIcon,
      meteorologicalService,
      menuLeftRef,
      showUserInfoText,
      clickFullMain,
      isFull,
      cachePages,
      isRefresh,
      routerKey,
      routerList,
      quickBg,
      closeTag,
      deleteRouterList,
      scrollbarRef
    }
  }
}
</script>

<style lang="scss">
.home {
  height: 100%;
  .el-header {
    height: 50px !important;
    padding: 0;
    line-height: 50px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    z-index: 2;
  }
  body > .el-container {
    margin-bottom: 40px;
  }
  .el-aside {
    transition: all 0.3s;
  }
  .el-aside-collapse {
    width: 64px !important;
  }
  .router-tag-box {
    z-index: 1;
    height: 35px !important;
    line-height: 35px;
    background-color: #fff;
    position: relative;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    // margin-bottom: 20px;
  }
  .el-main {
    background-color: #eff1f4;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    padding: 0;
    // height: calc(100vh - 50px);
    height: calc(100vh);
    overflow: hidden;
    position: relative;
    transition: all 0.3s;
  }
  // 全屏情况
  .mainFull{
    .scrollbarHeight {
      height: calc(100vh - 35px) !important;
    }
  }
  .scrollbarHeight {
    height: calc(100vh - 70px);
    // height: 100%;
  }
  .scrollbarLeftHeight {
    height: calc(100vh - 50px);
  }
}
</style>
