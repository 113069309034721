<template>
  <!--
    @name: CustomFormCheckBox
    @describe: 表单输Form checkBox 筛选框
    @author: L
    @date: 2021-07-13
 -->
  <el-form-item :label="label" :prop="prop" :required="required">
    <el-checkbox-group v-model="value" @change="checkBoxChange">
      <el-checkbox
        v-for="item in options"
        :key="keyName ? item[keyName] : item"
        :label="labelName ? item[labelName] : item.label"
        :name="valueName ? item[valueName] : item.name"
      ></el-checkbox>
    </el-checkbox-group>
  </el-form-item>
</template>

<script>
import { ref, watch } from 'vue'
export default {
  name: 'CustomFormcCheckBox',
  props: {
    // 是否必填
    required: {
      type: Boolean,
      default: false
    },
    // 描述
    label: {
      type: String
    },
    prop: {
      type: String
    },
    placeholder: {
      type: String
    },
    // 列表
    options: {
      type: Array,
      required: true,
      default: () => []
    },
    // 绑定Key名
    keyName: {
      type: String
    },
    // 描述名
    labelName: {
      type: String
    },
    // 值名
    valueName: {
      type: String,
      default: 'value'
    },
    // 是否多选
    multiple: {
      type: Boolean,
      default: false
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: [String, Array, Number],
      default: ''
    }
  },
  setup(props, context) {
    // 绑定值
    const value = ref(props.modelValue)
    // 监听双向绑定值是否改变
    watch(
      () => props.modelValue,
      () => {
        value.value = props.modelValue
      }
    )
    // 双向绑定值
    const checkBoxChange = nValue => {
      context.emit('update:modelValue', nValue)
      context.emit('change', nValue)
    }
    return { checkBoxChange, value }
  }
}
</script>

<style></style>
