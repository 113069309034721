/**
 * 入库记录管理接口
 */

const prefix = '/dseWeb'
export default [
  // 分页查询备品入库记录
  { name: 'getSparePutRecords', url: `${prefix}/v1/op/sparePutRecords`, method: 'get' },
  // 新增备品入库记录
  { name: 'addSparePutRecord', url: `${prefix}/v1/op/sparePutRecord`, method: 'post' },
  // 新增备品入库记录
  { name: 'updataSparePutRecord', url: `${prefix}/v1/op/sparePutRecord`, method: 'put' },
  // 删除备品入库记录
  { name: 'sparePutRecord', url: `${prefix}/v1/op/sparePutRecord`, method: 'delete' },
  // 入库记录详情
  { name: 'getSparePutRecord', url: `${prefix}/v1/op/sparePutRecord`, method: 'get' }
]
