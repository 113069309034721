/**
 * 巡检计划接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 分页查询巡检计划
    async fetchGetInspectPlans({ commit, getters }, params) {
      const { getInspectPlans } = getters.getHttps
      const { data } = await getInspectPlans(params)
      return setStoreApiDate(data, commit)
    },
    // 新增巡检计划
    async fetchAddInspectPlan({ commit, getters }, params) {
      const { addInspectPlan } = getters.getHttps
      const { data } = await addInspectPlan(params)
      return setStoreApiDate(data, commit)
    },
    // 修改巡检计划
    async fetchUpdateInspectPlan({ commit, getters }, params) {
      const { updateInspectPlan } = getters.getHttps
      const { data } = await updateInspectPlan(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单个巡检计划
    async fetchGetInspectPlan({ commit, getters }, planId) {
      const { getInspectPlan } = getters.getHttps
      const { data } = await getInspectPlan(false, false, {
        url: `${prefix}/v1/op/inspectPlan/${planId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 删除巡检计划
    async fetchRemoveInspectPlan({ commit, getters }, planId) {
      const { removeInspectPlan } = getters.getHttps
      const { data } = await removeInspectPlan(false, false, {
        url: `${prefix}/v1/op/inspectPlan/${planId}`
      })
      return setStoreApiDate(data, commit)
    }
  }
}
