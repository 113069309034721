/**
 * 登录接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 登录接口
    async fetchLogin({ commit, getters }, params) {
      const { Dologin } = getters.getHttps
      const { data } = await Dologin(params)
      return setStoreApiDate(data, commit, () => {
        if (data.data) {
          commit('setToken', data.data.authorization)
        }
      })
    },
    // token 查询
    async fetchLoginToken({ commit, getters }) {
      const { CurrentUser } = getters.getHttps
      const { data } = await CurrentUser()
      return setStoreApiDate(data, commit)
    },
    // 登出接口
    async fetchLoginOut({ commit, getters }) {
      const { LoginOut } = getters.getHttps
      const { data } = await LoginOut()
      return setStoreApiDate(data, commit)
    },
    // 获取验证码
    async fetchGetVeryCode({ commit, getters }) {
      const { GetVeryCode } = getters.getHttps
      const { data } = await GetVeryCode()
      return data
    }
  }
}
