/**
 * 公共接口
 */

const prefix = '/dseWeb'
export default [
  // 查询当前用户菜单集合
  { name: 'getUserModuleTreeList', url: `${prefix}/v1/op/userModuleTreeList`, method: 'get' },
  // 查询省市区字典树
  { name: 'getCityAreaTrees', url: `${prefix}/v1/op/cityAreaTrees`, method: 'get' },
  // 查询所有模块树形集合
  { name: 'getModuleAllTreeList', url: `${prefix}/v1/op/moduleAllTreeList`, method: 'get' },
  // 查询权限设置树形集合
  { name: 'getDistirbuteModuleTrees', url: `${prefix}/v1/op/distirbuteModuleTrees`, method: 'get' },
  // 查询下级集合
  { name: 'getDictChildren', url: `${prefix}/v1/op/dictChildren`, method: 'get' },
  // 下载模板
  { name: 'downloadModel', url: `${prefix}/v1/template/download`, method: 'get' },

  // 运维太势 *******************************************************************
  // 满意度统计
  { name: 'satisfactionStatistics', url: `${prefix}/v1/op/satisfactionStatistics`, method: 'get' },
  {
    name: 'monthEvaluateStatistics',
    url: `${prefix}/v1/op/monthEvaluateStatistics`,
    method: 'get'
  },
  // 最新季度收益统计
  { name: 'newQuarterlyEarnings', url: `${prefix}/v1/op/newQuarterlyEarnings`, method: 'get' },
  // 季度收益合同额排行统计
  {
    name: 'quarterlyEarningStatistics',
    url: `${prefix}/v1/op/quarterlyEarningStatistics`,
    method: 'get'
  },
  // 故障数量集合统计
  {
    name: 'errorCountStatisticsList',
    url: `${prefix}/v1/op/errorCountStatisticsList`,
    method: 'get'
  },
  // 故障告警数量统计
  {
    name: 'errorAlarmCountStatistics',
    url: `${prefix}/v1/op/errorAlarmCountStatistics`,
    method: 'get'
  },
  // 故障程度数量统计
  {
    name: 'errorLevelCountStatistics',
    url: `${prefix}/v1/op/errorLevelCountStatistics`,
    method: 'get'
  },
  // 故障数量统计
  {
    name: 'errorCountStatistics',
    url: `${prefix}/v1/op/errorCountStatistics`,
    method: 'get'
  },
  // 查询最新通知通告记录
  {
    name: 'newCompanyNoticeRecord',
    url: `${prefix}/v1/op/newCompanyNoticeRecord`,
    method: 'get'
  },
  // 查询所有负责人及工程师
  {
    name: 'getOtherCompanyUsers',
    url: `${prefix}/v1/op/otherCompanyUsers`,
    method: 'get'
  },
  // 查询本单位负责人及工程师
  {
    name: 'getMineCompanyUsers',
    url: `${prefix}/v1/op/mineCompanyUsers`,
    method: 'get'
  },
  // 单位统计信息集合
  {
    name: 'companyStatisticsList',
    url: `${prefix}/v1/op/companyStatisticsList`,
    method: 'get'
  },
  // 获取小程序信息
  {
    name: 'weixinAuthorizeInfo',
    url: `${prefix}/v1/weixinAuthorizeInfo`,
    method: 'get'
  },
  // 查询设备信息
  {
    name: 'getDeviceInfoShare',
    url: `${prefix}/v1/deviceInfo`,
    method: 'get'
  },
  // 查询设备故障记录
  {
    name: 'getDeviceErrorRecordsShare',
    url: `${prefix}/v1/deviceErrorRecords`,
    method: 'get'
  },
  // 项目设备人员数量统计
  {
    name: 'getProjectTotalStatistics',
    url: `${prefix}/v1/op/projectTotalStatistics`,
    method: 'get'
  },
  // 单位项目设备数量统计
  {
    name: 'getprojectDeviceTotalStatistics',
    url: `${prefix}/v1/op/projectDeviceTotalStatistics`,
    method: 'get'
  },
  // 故障状态数量统计
  {
    name: 'getdeviceErrorStatistics',
    url: `${prefix}/v1/op/deviceErrorStatistics`,
    method: 'get'
  },
  // 巡检类型数量统计
  {
    name: 'getinspectTypeTotalStatistics',
    url: `${prefix}/v1/op/inspectTypeTotalStatistics`,
    method: 'get'
  },
  // 单位故障程度数量统计
  {
    name: 'geterrorLevelCompanyStatistics',
    url: `${prefix}/v1/op/errorLevelCompanyStatistics`,
    method: 'get'
  },
  // 今日值班
  {
    name: 'gettodayDutyClassUser',
    url: `${prefix}/v1/op/todayDutyClassUser`,
    method: 'get'
  },
  //  项目设备故障巡检数量统计
  {
    name: 'getdeviceErrorInspectTotal',
    url: `${prefix}/v1/op/deviceErrorInspectTotal`,
    method: 'get'
  },
  //  登录次数统计
  {
    name: 'getloginLogCountStatistics',
    url: `${prefix}/v1/op/loginLogCountStatistics`,
    method: 'get'
  },
  //  巡检次数统计
  {
    name: 'getinspectLogCountStatistics',
    url: `${prefix}/v1/op/inspectLogCountStatistics`,
    method: 'get'
  },
  //  故障上报次数统计
  {
    name: 'geterrorLogCountStatistics',
    url: `${prefix}/v1/op/errorLogCountStatistics`,
    method: 'get'
  },
  // 查询待处理信息（故障、巡检）
  {
    name: 'getDeviceErrorInspectList',
    url: `${prefix}/v1/op/DeviceErrorInspectList`,
    method: 'get'
  },
  // 查询用户列表
  {
    name: 'getSelectUserByParam',
    url: `${prefix}/v1/op/selectUserByParam`,
    method: 'post'
  }
]
