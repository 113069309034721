/**
 * 季度收益接口
 */

const prefix = '/dseWeb'
export default [
  // 分页查询季度收益
  { name: 'getQuarterlyEarnings', url: `${prefix}/v1/op/quarterlyEarnings`, method: 'get' },
  // 删除季度收益
  { name: 'deleteQuarterlyEarning', url: `${prefix}/v1/op/quarterlyEarning`, method: 'delete' },
  // 添加季度收益
  { name: 'addQuarterlyEarning', url: `${prefix}/v1/op/quarterlyEarning`, method: 'post' },
  // 修改季度收益
  { name: 'updateQuarterlyEarning', url: `${prefix}/v1/op/quarterlyEarning`, method: 'put' },
  // 查询单个季度收益
  { name: 'getQuarterlyEarning', url: `${prefix}/v1/op/quarterlyEarning`, method: 'get' },
  // 分页查询季度收益记录
  {
    name: 'getQuarterlyEarningRecords',
    url: `${prefix}/v1/op/quarterlyEarningRecords`,
    method: 'get'
  },
  // 删除季度收益记录
  {
    name: 'quarterlyEarningRecord',
    url: `${prefix}/v1/op/quarterlyEarningRecord`,
    method: 'delete'
  }
]
