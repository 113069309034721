/**
 * 事件管理接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 分页查询事件短信发送记录
    async fetchGetEventSendRecords({ commit, getters }, params) {
      const { getEventSendRecords } = getters.getHttps
      const { data } = await getEventSendRecords(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单个事件短信发送记录
    async fetchGetEventSendRecord({ commit, getters }, recordId) {
      const { getEventSendRecord } = getters.getHttps
      const { data } = await getEventSendRecord(false, false, {
        url: `${prefix}/v1/op/eventSendRecord/${recordId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 分页查询事件模板
    async fetchgEtEventTemplates({ commit, getters }, params) {
      const { getEventTemplates } = getters.getHttps
      const { data } = await getEventTemplates(params)
      return setStoreApiDate(data, commit)
    },
    // 新增事件模板
    async fetchAddEventTemplate({ commit, getters }, params) {
      const { addEventTemplate } = getters.getHttps
      const { data } = await addEventTemplate(params)
      return setStoreApiDate(data, commit)
    },
    // 修改事件模板
    async fetchUpdateEventTemplate({ commit, getters }, params) {
      const { updateEventTemplate } = getters.getHttps
      const { data } = await updateEventTemplate(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单个事件模板
    async fetchGetEventTemplate({ commit, getters }, templateId) {
      const { getEventTemplate } = getters.getHttps
      const { data } = await getEventTemplate(false, false, {
        url: `${prefix}/v1/op/eventTemplate/${templateId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 删除事件模板
    async fetchDeleteEventTemplate({ commit, getters }, templateId) {
      const { deleteEventTemplate } = getters.getHttps
      const { data } = await deleteEventTemplate(false, false, {
        url: `${prefix}/v1/op/eventTemplate/${templateId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 分页查询事件数据记录
    async fetchGetEventDataRecords({ commit, getters }, params) {
      const { getEventDataRecords } = getters.getHttps
      const { data } = await getEventDataRecords(params)
      return setStoreApiDate(data, commit)
    },
    // 查询预警事件是否自动发送
    async fetchGetEventAlarmStatus({ commit, getters }) {
      const { getEventAlarmStatus } = getters.getHttps
      const { data } = await getEventAlarmStatus()
      return setStoreApiDate(data, commit)
    },
    // 修改预警事件是否自动发送状态
    async fetchUpdateEventAlarmStatus({ commit, getters }, status) {
      const { updateEventAlarmStatus } = getters.getHttps
      const { data } = await updateEventAlarmStatus(false, false, {
        url: `/dseWeb/v1/op/eventAlarmStatus?status=${status}`
      })
      return setStoreApiDate(data, commit)
    },
    // 手动发送事件数据记录
    async fetchEventDataRecordSend({ commit, getters }, params) {
      const { eventDataRecordSend } = getters.getHttps
      const { data } = await eventDataRecordSend(params)
      return setStoreApiDate(data, commit)
    }
  }
}
