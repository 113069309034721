const prefix = '/dseWeb'
export default [
  // 分页查询巡检计划
  {
    name: 'getOpsReportPage',
    url: `${prefix}/v1/op/devOpsReport/Page`,
    method: 'post'
  },
  // 生成运维报告
  {
    name: 'generateOpsReport',
    url: `${prefix}/v1/op/devOpsReport/generateReport`,
    method: 'post'
  },
  // 获取项目信息
  {
    name: 'getReportProjectInfo',
    url: `${prefix}/v1/op/devOpsReport/getProjectInfo`,
    method: 'post'
  },
  // 设备异常数据统计
  {
    name: 'getDeviceErrorCount',
    url: `${prefix}/v1/op/devOpsReport/getDeviceErrorCount`,
    method: 'post'
  },
  // 巡检信息统计
  {
    name: 'getInspectCount',
    url: `${prefix}/v1/op/devOpsReport/getInspectCount`,
    method: 'post'
  },
  // 导出运维报告
  {
    name: 'exportReport',
    url: `${prefix}/v1/op/devOpsReport/exportReport`,
    method: 'get'
  }
]
