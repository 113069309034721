/**
 * 后台用户接口
 */

const prefix = '/dseWeb'
export default [
  // 查询登录后台账号
  { name: 'getCurrentUser', url: `${prefix}/v1/op/currentUser`, method: 'get' },
  // 修改密码
  { name: 'updateUserPass', url: `${prefix}/v1/op/updateUserPass`, method: 'put' },
  // 分页查询后台用户
  { name: 'getSysUsers', url: `${prefix}/v1/op/sysUsers`, method: 'get' },
  // 查询阿米巴单位下所有用户
  { name: 'getAmoebaBelowSysUsers', url: `${prefix}/v1/op/amoebaBelowSysUsers`, method: 'get' },
  // 新增后台用户
  { name: 'addSysUser', url: `${prefix}/v1/op/sysUser`, method: 'post' },
  // 悠2后台用户
  { name: 'updateSysUser', url: `${prefix}/v1/op/sysUser`, method: 'put' },
  // 查询单个后台用户
  { name: 'getSysUser', url: `${prefix}/v1/op/sysUser`, method: 'get' },
  // 删除后台用户
  { name: 'deleteSysUser', url: `${prefix}/v1/op/sysUser`, method: 'delete' },
  // 重置密码
  { name: 'resetUserPass', url: `${prefix}/v1/op/resetUserPass`, method: 'put' },
  // 分页查询系统日志信息
  { name: 'sysLogInfos', url: `${prefix}/v1/op/sysLogInfos`, method: 'get' }
]
