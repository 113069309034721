/**
 * 流程管理接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 分页查询工单审核流程
    async fetchGetOrderProcesss({ commit, getters }, params) {
      const { getOrderProcesss } = getters.getHttps
      const { data } = await getOrderProcesss(params)
      return setStoreApiDate(data, commit)
    },
    // 保存工单审核流程
    async fetchAddOrderProcess({ commit, getters }, params) {
      const { addOrderProcess } = getters.getHttps
      const { data } = await addOrderProcess(params)
      return setStoreApiDate(data, commit)
    },
    // 修改工单审核流程
    async fetchUpdateOrderProcess({ commit, getters }, params) {
      const { updateOrderProcess } = getters.getHttps
      const { data } = await updateOrderProcess(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单个工单审核流程
    async fetchGetOrderProcess({ commit, getters }, processId) {
      const { getOrderProcess } = getters.getHttps
      const { data } = await getOrderProcess(false, false, {
        url: `${prefix}/v1/op/orderProcess/${processId}`
      })
      return setStoreApiDate(data, commit)
    }
  }
}
