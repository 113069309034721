<template>
  <!--
    @name: CustomFormDatePicker
    @describe: 表单输Form date picker 日期选择框
    @author: L
    @date: 2021-06-30
 -->
  <el-form-item :label="label" :prop="prop" :required="required">
    <el-date-picker
      v-model="value"
      :type="type"
      :range-separator="range"
      :start-placeholder="startPlaceholder"
      :end-placeholder="endPlaceholder"
      :placeholder="placeholder"
      :format="format"
      :value-format="valueFormat"
      :disabled="disabled"
      :disabled-date="disabledDate"
      @change="datePickerChenge"
    >
    </el-date-picker>
  </el-form-item>
</template>

<script>
import { ref, watch } from 'vue'
export default {
  name: 'CustomFormDatePicker',
  props: {
    // 是否必填
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // 描述
    label: {
      type: String
    },
    // 格式化
    format: {
      type: String
    },
    valueFormat: {
      type: String
    },
    prop: {
      type: String
    },
    // 描述
    placeholder: {
      type: String
    },
    // 开始描述
    startPlaceholder: {
      type: String
    },
    // 结束描述
    endPlaceholder: {
      type: String
    },
    // 至隔号
    range: {
      type: String
    },
    // 类型
    type: {
      type: String,
      default: 'daterange'
    },
    disabledDate: {
      type: Function
    },
    modelValue: {
      type: [String, Array, Date],
      default: () => []
    }
  },
  setup(props, context) {
    // 绑定值
    const value = ref(props.modelValue || [])
    // 监听双向绑定值是否改变
    watch(
      () => props.modelValue,
      () => {
        value.value = props.modelValue
      }
    )
    // 双向绑定值
    const datePickerChenge = nValue => {
      const valType = props.type.indexOf('range') >= 0 ? [] : ''
      context.emit('update:modelValue', nValue || valType)
      context.emit('change', nValue || valType)
    }
    return { datePickerChenge, value }
  }
}
</script>

<style></style>
