/**
 * 公共接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 查询当前用户菜单集合
    async fetchGetUserModuleTreeList({ commit, getters }, params) {
      const { getUserModuleTreeList } = getters.getHttps
      const { data } = await getUserModuleTreeList(params)
      return setStoreApiDate(data, commit)
    },
    // 查询省市区字典树
    async fetchGetCityAreaTrees({ commit, getters }, params) {
      const { getCityAreaTrees } = getters.getHttps
      const { data } = await getCityAreaTrees(params)
      return setStoreApiDate(data, commit)
    },
    // 查询所有模块树形集合
    async fetchGetModuleAllTreeList({ commit, getters }, params) {
      const { getModuleAllTreeList } = getters.getHttps
      const { data } = await getModuleAllTreeList(params)
      return setStoreApiDate(data, commit)
    },
    // 查询权限设置树形集合
    async fetchGetDistirbuteModuleTrees({ commit, getters }, params) {
      const { getDistirbuteModuleTrees } = getters.getHttps
      const { data } = await getDistirbuteModuleTrees(params)
      return setStoreApiDate(data, commit)
    },
    // 查询下级集合
    async fetchGetDictChildren({ commit, getters }, params) {
      const { getDictChildren } = getters.getHttps
      const { data } = await getDictChildren(params)
      return setStoreApiDate(data, commit)
    },
    // 下载模板
    async fetchDownloadModel({ commit, getters }, params) {
      const { downloadModel } = getters.getHttps
      const { data } = await downloadModel(params)
      return setStoreApiDate(data, commit)
    },

    // 运维太势 *******************************************************************
    // 满意度统计
    async fetchSatisfactionStatistics({ commit, getters }) {
      const { satisfactionStatistics } = getters.getHttps
      const { data } = await satisfactionStatistics()
      return setStoreApiDate(data, commit)
    },
    async fetchmonthEvaluateStatistics({ commit, getters }) {
      const { monthEvaluateStatistics } = getters.getHttps
      const { data } = await monthEvaluateStatistics()
      return setStoreApiDate(data, commit)
    },
    // 最新季度收益统计
    async fetchNewQuarterlyEarnings({ commit, getters }) {
      const { newQuarterlyEarnings } = getters.getHttps
      const { data } = await newQuarterlyEarnings()
      return setStoreApiDate(data, commit)
    },
    // 季度收益合同额排行统计
    async fetchQuarterlyEarningStatistics({ commit, getters }) {
      const { quarterlyEarningStatistics } = getters.getHttps
      const { data } = await quarterlyEarningStatistics()
      return setStoreApiDate(data, commit)
    },
    // 故障数量集合统计
    async fetchErrorCountStatisticsList({ commit, getters }) {
      const { errorCountStatisticsList } = getters.getHttps
      const { data } = await errorCountStatisticsList()
      return setStoreApiDate(data, commit)
    },
    // 故障告警数量统计
    async fetchErrorAlarmCountStatistics({ commit, getters }) {
      const { errorAlarmCountStatistics } = getters.getHttps
      const { data } = await errorAlarmCountStatistics()
      return setStoreApiDate(data, commit)
    },
    // 故障程度数量统计
    async fetchErrorLevelCountStatistics({ commit, getters }) {
      const { errorLevelCountStatistics } = getters.getHttps
      const { data } = await errorLevelCountStatistics()
      return setStoreApiDate(data, commit)
    },
    // 故障数量统计
    async fetchErrorCountStatistics({ commit, getters }) {
      const { errorCountStatistics } = getters.getHttps
      const { data } = await errorCountStatistics()
      return setStoreApiDate(data, commit)
    },
    // 故障数量统计
    async fetchNewCompanyNoticeRecord({ commit, getters }) {
      const { newCompanyNoticeRecord } = getters.getHttps
      const { data } = await newCompanyNoticeRecord()
      return setStoreApiDate(data, commit)
    },
    // 查询所有负责人及工程师
    async fetchGetOtherCompanyUsers({ commit, getters }) {
      const { getOtherCompanyUsers } = getters.getHttps
      const { data } = await getOtherCompanyUsers()
      return setStoreApiDate(data, commit)
    },
    // 查询本单位负责人及工程师
    async fetchGetMineCompanyUsers({ commit, getters }) {
      const { getMineCompanyUsers } = getters.getHttps
      const { data } = await getMineCompanyUsers()
      return setStoreApiDate(data, commit)
    },
    // 单位统计信息集合
    async fetchCompanyStatisticsList({ commit, getters }) {
      const { companyStatisticsList } = getters.getHttps
      const { data } = await companyStatisticsList()
      return setStoreApiDate(data, commit)
    },
    // 获取小程序信息
    async fetchWeixinAuthorizeInfo({ commit, getters }, params) {
      const { weixinAuthorizeInfo } = getters.getHttps
      const { data } = await weixinAuthorizeInfo(params)
      return setStoreApiDate(data, commit)
    },
    // 查询设备信息
    async fetchGetDeviceInfoShare({ commit, getters }, deviceId) {
      const { getDeviceInfoShare } = getters.getHttps
      const { data } = await getDeviceInfoShare(false, false, {
        url: `${prefix}/v1/deviceInfo/${deviceId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 查询设备故障记录
    async fetchGetDeviceErrorRecordsShare({ commit, getters }, params) {
      const { getDeviceErrorRecordsShare } = getters.getHttps
      const { data } = await getDeviceErrorRecordsShare(params)
      return setStoreApiDate(data, commit)
    },
    // 项目设备人员数量统计
    async fetchGetProjectTotalStatistics({ commit, getters }) {
      const { getProjectTotalStatistics } = getters.getHttps
      const { data } = await getProjectTotalStatistics()
      return setStoreApiDate(data, commit)
    },
    // 运单位项目设备数量统计
    async fetchgetprojectDeviceTotalStatistics({ commit, getters }) {
      const { getprojectDeviceTotalStatistics } = getters.getHttps
      const { data } = await getprojectDeviceTotalStatistics()
      return setStoreApiDate(data, commit)
    },
    // 故障状态数量统计
    async fetchgetdeviceErrorStatistics({ commit, getters }) {
      const { getdeviceErrorStatistics } = getters.getHttps
      const { data } = await getdeviceErrorStatistics()
      return setStoreApiDate(data, commit)
    },
    // 巡检类型数量统计
    async fetchgetinspectTypeTotalStatistics({ commit, getters }) {
      const { getinspectTypeTotalStatistics } = getters.getHttps
      const { data } = await getinspectTypeTotalStatistics()
      return setStoreApiDate(data, commit)
    },
    // 单位故障程度数量统计
    async fetchgeterrorLevelCompanyStatistics({ commit, getters }) {
      const { geterrorLevelCompanyStatistics } = getters.getHttps
      const { data } = await geterrorLevelCompanyStatistics()
      return setStoreApiDate(data, commit)
    },
    // 今日值班
    async fetchgettodayDutyClassUser({ commit, getters }, params) {
      const { gettodayDutyClassUser } = getters.getHttps
      const { data } = await gettodayDutyClassUser(params)
      return setStoreApiDate(data, commit)
    },
    // 项目设备故障巡检数量统计
    async fetchgetdeviceErrorInspectTotal({ commit, getters }, params) {
      const { getdeviceErrorInspectTotal } = getters.getHttps
      const { data } = await getdeviceErrorInspectTotal(params)
      return setStoreApiDate(data, commit)
    },
    // 登录次数统计
    async fetchgetloginLogCountStatistics({ commit, getters }, params) {
      const { getloginLogCountStatistics } = getters.getHttps
      const { data } = await getloginLogCountStatistics(params)
      return setStoreApiDate(data, commit)
    },
    // 巡检次数统计
    async fetchgetinspectLogCountStatistics({ commit, getters }, params) {
      const { getinspectLogCountStatistics } = getters.getHttps
      const { data } = await getinspectLogCountStatistics(params)
      return setStoreApiDate(data, commit)
    },
    // 故障上报次数统计
    async fetchgeterrorLogCountStatistics({ commit, getters }, params) {
      const { geterrorLogCountStatistics } = getters.getHttps
      const { data } = await geterrorLogCountStatistics(params)
      return setStoreApiDate(data, commit)
    },
    // 查询（故障、巡检）待处理信息
    async fetchGetDeviceErrorInspectList({ commit, getters }, params) {
      const { getDeviceErrorInspectList } = getters.getHttps
      const { data } = await getDeviceErrorInspectList(params)
      return setStoreApiDate(data, commit)
    },
    // 查询用户列表
    async fetchGetSelectUserByParam({ commit, getters }, params) {
      const { getSelectUserByParam } = getters.getHttps
      const { data } = await getSelectUserByParam(params)
      return setStoreApiDate(data, commit)
    },
  }
  
}
