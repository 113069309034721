<template>
  <div class="router-tag">
    <div class="l-tag">
      <el-scrollbar class="router-tag-scrollbar">
        <router-link
          class="router-tag-link"
          :class="{ 'router-link-exact-active': route.meta.path === item.path }"
          :to="item.path"
          v-for="(item, index) in list"
          :key="item"
        >
          <el-tag
            :closable="list.length > 1"
            class="tag-item"
            size="mini"
            effect="dark"
            @close.prevent="closeTag(index)"
          >
            <i class="round"></i><span>{{ item.meta.title || item.name }}</span>
          </el-tag>
        </router-link>
      </el-scrollbar>
    </div>
    <div class="l-tag-tool">
      <i class="el-icon-delete" @click="deleteTouterList"></i>
      <i class="el-icon-refresh" @click="refresh"></i>
      <i :class="isFull ? 'ml-icon-small-screen' : 'ml-icon-full-screen'" @click="fullMain"></i>
    </div>
  </div>
</template>

<script>
import { computed, inject } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
export default {
  name: 'RouterTag',
  emits: ['click-full-main', 'close-tag', 'delete-router-list'],
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    }
  },
  setup(props, context) {
    const route = useRoute()
    const { commit, getters } = useStore()
    const isFull = inject('isFull')
    const isRefresh = computed(() => getters.getIsRefresh)
    // 刷新页面
    const refresh = () => {
      if (!isRefresh.value) {
        commit('setIsRefresh', true)
        setTimeout(() => {
          commit('setIsRefresh', false)
          commit('setError', {
            status: true,
            message: '刷新成功',
            type: 'success'
          })
        }, 200)
      }
    }
    // 全屏
    const fullMain = () => {
      context.emit('click-full-main')
      refresh()
    }
    // 删除单个路由快捷
    const closeTag = index => {
      context.emit('close-tag', index)
    }
    // 清除路由快捷
    const deleteTouterList = () => {
      context.emit('delete-router-list')
    }
    return { route, fullMain, isFull, refresh, closeTag, deleteTouterList }
  }
}
</script>

<style lang="scss">
.router-link-exact-active .tag-item {
  background-color: $--color-primary !important;
  color: #fff !important;
  border-color: $--color-primary !important;
}
.router-tag {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  &-scrollbar {
    width: calc(100vw - 300px);
  }
  &-link {
    margin-left: 10px;
  }
  .l-tag-tool {
    padding: 0 10px;
    i {
      margin: 0 5px;
      cursor: pointer;
    }
  }
  .router-link-exact-active {
    .tag-item {
      .el-tag__close {
        color: #fff !important;
      }
      .round {
        display: inline-block;
      }
    }
  }
  .tag-item {
    padding: 0 8px;
    height: 26px;
    line-height: 26px;
    border-radius: 2px;
    border: 1px solid #d8dce5;
    color: #495060;
    background: #fff;
    .el-tag__close {
      color: #495060 !important;
    }
    .round {
      content: '';
      background: #fff;
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: relative;
      margin-right: 2px;
      margin: 5px;
      vertical-align: middle;
      display: none;
    }
  }
}
</style>
