/**
 * 公共接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 分页查询投诉建议记录
    async fetchGetSuggestRecords({ commit, getters }, params) {
      const { getSuggestRecords } = getters.getHttps
      const { data } = await getSuggestRecords(params)
      return setStoreApiDate(data, commit)
    }
  }
}
