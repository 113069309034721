<template>
  <!--
    @name: CustomFormCascader
    @describe: 表单输Form cascader 多级下拉选择框
    @author: L
    @date: 2021-06-25
 -->
  <el-form-item :label="label" :prop="prop" :required="required">
    <el-cascader
      :style="cascaderStyle"
      v-model="value"
      :props="props"
      :options="options"
      @change="handleChange"
      :placeholder="placeholder"
      :clearable="clearable"
      :collapse-tags="collapseTags"
      :filterable="filterable"
      :show-all-levels="showAllLevels"
    >
      <template #default="{ data }">
        <span>{{ props.label ? data[props.label] : data.label }}</span>
      </template>
    </el-cascader>
  </el-form-item>
</template>

<script>
import { ref, watch } from 'vue'
export default {
  name: 'CustomFormInput',
  props: {
    // 是不必填
    required: {
      type: Boolean,
      default: false
    },
    // 是否可搜索
    filterable: {
      type: Boolean,
      default: false
    },
    // 是否必填
    collapseTags: {
      type: Boolean,
      default: false
    },
    showAllLevels: {
      type: Boolean,
      default: false
    },
    // 描述
    label: {
      type: String
    },
    prop: {
      type: String
    },
    placeholder: {
      type: String
    },
    cascaderStyle: {
      type: [String, Object],
      default: ''
    },
    labelKey: {
      type: String
    },
    props: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 原始列表
    options: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    area: {
      type: String,
      default: ''
    },
    node: {
      type: [String, Object, Array],
      default: ''
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    // 绑定值
    const value = ref(props.modelValue || [])
    // 监听双向绑定值是否改变
    watch(
      () => props.modelValue,
      () => {
        value.value = props.modelValue
      }
    )


    // wang
    // 双向绑定值
    const handleChange_old = (nValue = []) => {
      // 父级子级结构
      nValue = nValue === null ? [] : nValue
      context.emit('update:modelValue', nValue)
      // context.emit('update:area', nValue ? nValue[nValue.length - 1] : nValue)

      // 选中数据
      const newValue = nValue.map(item => {
        if (props.props && props.props.multiple) {
          return item[0]
        } else {
          return item
        }
      })
      context.emit('update:area', newValue)
      const nNode = []

      // 更新选项列表
      props.options.map(item => {

        // 有子级
        if (item.children && item.children.length > 0) {
          
          let nObj = {}

          // 筛选到选中数据
          const nchildren = item.children.filter(v => newValue.includes(v[props.props.value]))
          // console.log('-------nchildren')
          // console.log(nchildren)
          if (nchildren.length > 0) {
            nObj = item
            nObj.children = nchildren
          }
          if (Object.keys(nObj).length > 0) {
            nNode.push(nObj)
          }
          console.log('-------item.children')
          console.log(item.children)
        } else {//单级
          if (item[props.props.value] && newValue.includes(item[props.props.value])) {
            nNode.push(item)
          }
        }

        // if (props.props && props.props.value) {
        //   if (item.children) {
        //     const nchildren = item.children.map(v => v[props.props.value])
        //     if (item[props.props.value]) {
        //       return nchildren.includes(item[props.props.value])
        //     }
        //   } else {
        //     if (item[props.props.value]) {
        //       return newValue.includes(item[props.props.value])
        //     }
        //   }
        // } else {
        //   return newValue.includes(item.value)
        // }
      })
      
      // console.log('-------更新node')
      // console.log(nNode)
      context.emit('update:node', nNode)
      context.emit('change')
    }

    // 新处理
    // 双向绑定值
    const handleChange = (nValue = []) => {
      // 父子级结构
      nValue = nValue === null ? [] : nValue
      context.emit('update:modelValue', nValue)
      // context.emit('update:area', nValue ? nValue[nValue.length - 1] : nValue)

      // 选中数据
      const newValue = nValue.map(item => {
        if (props.props && props.props.multiple) {
          return item[0]
        } else {
          return item
        }
      })
      context.emit('update:area', newValue)
      const nNode = []

      // 更新选项列表
      props.options.map(item => {

        // 有子级
        if (item.children && item.children.length > 0) {
          
          let nObj = {}

          // 筛选到选中数据
          const nchildren = item.children.filter(v => newValue.includes(v[props.props.value]))
          if (nchildren.length > 0) {
            nObj = item
            // nObj.children = nchildren
          }
          if (Object.keys(nObj).length > 0) {
            nNode.push(nObj)
          }
        } else {//单级
          if (item[props.props.value] && newValue.includes(item[props.props.value])) {
            nNode.push(item)
          }
        }
      })
      
      // console.log('-------更新node')
      // console.log(nNode)
      context.emit('update:node', nNode)
      context.emit('change')
    }

    return { handleChange, value }
  }
}
</script>

<style></style>
