/**
 * 工单草稿接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 新增工单草稿
    async fetchAddOrderDraft({ commit, getters }, params) {
      const { addOrderDraft } = getters.getHttps
      const { data } = await addOrderDraft(params)
      return setStoreApiDate(data, commit)
    },
    // 查询工单草稿
    async fetchGetOrderDraft({ commit, getters }, params) {
      const { getOrderDraft } = getters.getHttps
      const { data } = await getOrderDraft(params)
      return setStoreApiDate(data, commit)
    }
  }
}
