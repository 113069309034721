/**
 * 备品备件管理接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 分页查询备品备件库
    async fetchGetspareParts({ commit, getters }, params) {
      const { getSpareParts } = getters.getHttps
      const { data } = await getSpareParts(params)
      return setStoreApiDate(data, commit)
    },
    // 新增备品备件库
    async fetchAddSparePart({ commit, getters }, params) {
      const { addSparePart } = getters.getHttps
      const { data } = await addSparePart(params)
      return setStoreApiDate(data, commit)
    },
    // 修改备品备件库
    async fetchUpdateSparePart({ commit, getters }, params) {
      const { updateSparePart } = getters.getHttps
      const { data } = await updateSparePart(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单个备品备件库
    async fetchGetsparePart({ commit, getters }, partId) {
      const { getSparePart } = getters.getHttps
      const { data } = await getSparePart(false, false, {
        url: `${prefix}/v1/op/sparePart/${partId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 删除备品备件库
    async fetchDeleteSparePart({ commit, getters }, partId) {
      const { deleteSparePart } = getters.getHttps
      const { data } = await deleteSparePart(false, false, {
        url: `${prefix}/v1/op/sparePart/${partId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 备品备件Excel模板导入
    async fetchSparePartExcelImport({ commit, getters }, params) {
      const { sparePartExcelImport } = getters.getHttps
      const { data } = await sparePartExcelImport(params, true)
      return setStoreApiDate(data, commit)
    },
    // 获取审核流程列表
    async fetchOrderProcesss({ commit, getters }, params) {
      const { orderProcesss } = getters.getHttps
      const { data } = await orderProcesss(params)
      return setStoreApiDate(data, commit)
    },
    // 审核退回接口
    async fetchApprProcess({ commit, getters }, params) {
      const { apprProcess } = getters.getHttps
      const { data } = await apprProcess(params)
      return setStoreApiDate(data, commit)
    },
    // 根据当前处理节点
    async fetchGetCurNode({ commit, getters }, params) {
      const { getCurNode } = getters.getHttps
      const { data } = await getCurNode(params)
      return setStoreApiDate(data, commit)
    },
    // 是否存在未处理完的出库/入库流程
    async fetchExistsSpareProcess({ commit, getters }, params) {
      const { existsSpareProcess } = getters.getHttps
      const { data } = await existsSpareProcess(params)
      return setStoreApiDate(data, commit)
    }
  }
}
