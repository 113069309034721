<template>
  <!--
    @name: CustomFormInput
    @describe: 表单输Form input 输入框
    @author: L
    @date: 2021-05-25
 -->
  <el-form-item :label="label" :label-width="labelWidth" :prop="prop" :required="required">
    <el-input
      :name="prop"
      :prefix-icon="prefixIcon"
      @input="updateValue"
      v-model.trim="value"
      :placeholder="placeholder"
      autocomplete="off"
      :type="type"
      :rows="rows"
      :input-style="inputStyle"
      :readonly="readonly"
      :disabled="disabled"
      :clearable="clearable"
      :show-password="showPassword"
      :maxlength="maxlength"
      :show-word-limit="showWordLimit"
    ></el-input>
  </el-form-item>
</template>

<script>
import { ref, watch } from 'vue'
export default {
  name: 'CustomFormInput',
  props: {
    // 是否密码框
    showPassword: {
      type: Boolean,
      default: false
    },
    showWordLimit: {
      type: Boolean,
      default: false
    },
    // 类型
    type: {
      type: String,
      default: 'text'
    },
    // 长度
    maxlength: {
      type: [String, Number]
    },
    // label宽
    labelWidth: {
      type: String
    },
    // input样式
    inputStyle: {
      type: Object
    },
    // type="textarea" 有效
    rows: {
      type: Number
    },
    // 是不必填
    required: {
      type: Boolean,
      default: false
    },
    // 是不只读
    readonly: {
      type: Boolean,
      default: false
    },
    // 是不禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 图标
    prefixIcon: {
      type: String
    },
    // 描述
    label: {
      type: String
    },
    prop: {
      type: String
    },
    placeholder: {
      type: String
    },
    modelValue: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: false
    },
    value:{
      type: String,
      default: ''
    }
  },
  setup(props, context) {
    // 绑定值
    const value = ref(props.modelValue || props.value)
    // 监听双向绑定值是否改变
    watch(
      () => props.modelValue,
      () => {
        value.value = props.modelValue
      }
    )
    // 双向绑定值
    const updateValue = nValue => {
      context.emit('update:modelValue', nValue)
    }
    return { updateValue, value }
  }
}
</script>

<style></style>
