import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import ElementPlus from 'element-plus'
// import 'element-plus/lib/theme-chalk/index.css'
// import zhCn from 'element-plus/lib/locale/lang/zh-cn'

import 'element-plus/lib/theme-chalk/index.css'
import ElementPlus from 'element-plus'
//import zhCn from 'element-plus/es/locale/lang/zh-cn'
// import '../theme/index.css'
import { Calendar } from 'vant'
import 'vant/lib/index.css'

// 全局组件
import ElementWml from './element-wml'
// vue粒子特效
import vueParticles from 'vue-particles'
// 上传资源管理器
import UploadDlg from './components/uploadDlg.vue'

// 设置区域
 //import { getArea } from '@/utils/areaData'
 //store.commit('setArea', getArea())

import CreateAxios from './axios'
/* eslint-disable no-new */
new CreateAxios(store)

//.use(ElementPlus, { locale })
createApp(App)
  .component('c-upload-dlg', UploadDlg)
  .use(ElementPlus)
  .use(vueParticles)
  .use(ElementWml)
  .use(Calendar)
  .use(store)
  .use(router)
  .mount('#app')
