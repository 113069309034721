<template>
  <!--
    @name: CustomFormInputNumber
    @describe: 表单输Form input number 输入框-计数器
    @author: L
    @date: 2021-05-25
 -->
  <el-form-item :label="label" :prop="prop" :required="required">
    <el-input-number
      @change="changeValue"
      @blur="blurValue"
      v-model="value"
      :disabled="disabled"
      :precision="precision"
      :min="min"
      :max="max"
      :step="step"
      :placeholder="placeholder"
      :controls-position="controlsPosition"
    ></el-input-number>
  </el-form-item>
</template>

<script>
import { ref, watch } from 'vue'
export default {
  name: 'CustomFormInput',
  props: {
    // 最小值
    min: {
      type: Number
    },
    // 最大值
    max: {
      type: Number
    },
    // 精度
    precision: {
      type: Number
    },
    // 步长
    step: {
      type: Number
    },
    // 是否必填
    required: {
      type: Boolean,
      default: false
    },
    // 描述
    label: {
      type: String
    },
    // 默认提示
    placeholder: {
      type: String
    },
    // 按钮位置
    controlsPosition: {
      type: String,
      default: 'right'
    },
    prop: {
      type: String
    },
    modelValue: {
      type: Number
    },
    disabled:{
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    // 绑定值
    const value = ref(props.modelValue || 0)
    // 监听双向绑定值是否改变
    watch(
      () => props.modelValue,
      () => {
        value.value = props.modelValue
      }
    )
    // 双向绑定值
    const changeValue = nValue => {
      context.emit('update:modelValue', nValue)
    }
    // 双向绑定值
    const blurValue = () => {
      value.value = !value.value ? 0 : value.value
    }
    return { changeValue, blurValue, value }
  }
}
</script>

<style></style>
