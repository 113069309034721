<template>
  <div class="upload-folder-item" :style="{ borderColor: activeStyle }">
    <div class="upload-folder-item-icon">
      <el-image :style="{ width: '48.39px', height: '44.76px' }" :src="setIcon({ name })" />
    </div>
    <div class="upload-folder-item-name">
      <span :title="name">{{ name }}</span>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'

export default {
  name: 'FolderItem',
  props: {
    name: {
      type: String,
      required: true,
      default: 'new folder'
    },
    isTrue: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const dir = require('@assets/image/map/dir.png')
    // const diractive = require('@assets/image/map/dirActive.png')
    // const folderIcom = ref(props.isTrue ? diractive : dir)

    const activeStyle = ref('0')
    watch(
      () => props.isTrue,
      newvalue => {
        if (newvalue) {
          // folderIcom.value = diractive
          activeStyle.value = ' #d8d8d8'
        } else {
          activeStyle.value = 'transparent'
        }
      }
    )

    // 获取图标
    const setIcon = item => {
      const fileName = props.fileName ? item[props.fileName] : item['name']
      let fileType = ''
      if (fileName) {
        fileType = fileName.substring(fileName.lastIndexOf('.') + 1)
        switch (fileType) {
          case 'jpg':
            fileType = icons.img
            break
          case 'jpeg':
            fileType = icons.img
            break
          case 'png':
            fileType = icons.img
            break
          case 'gif':
            fileType = icons.img
            break
          case 'doc':
            fileType = icons.w
            break
          case 'xlsx':
            fileType = icons.x
            break
          case 'xls':
            fileType = icons.x
            break
          case 'txt':
            fileType = icons.t
            break
          case 'rar':
            fileType = icons.rar
            break
          case 'zip':
            fileType = icons.rar
            break
          default:
            fileType = dir
            break
        }
      } else {
        fileType = dir
      }
      return fileType
    }

    // 文件类型
    const icons = {
      f: require('../assets/image/file/f.png'),
      img: require('../assets/image/file/img.png'),
      rar: require('../assets/image/file/rar.png'),
      t: require('../assets/image/file/t.png'),
      w: require('../assets/image/file/w.png'),
      x: require('../assets/image/file/x.png')
    }
    return { activeStyle, setIcon }
  }
}
</script>

<style lang="scss">
.upload-folder-item {
  width: 100px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  margin: 10px;
  padding: 10px 0;
  border: 1px dashed transparent;
  cursor: pointer;
  &-name {
    width: 100px;
    color: #707070;
    font-weight: bold;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 10px;
    box-sizing: border-box;
    text-align: center;
  }
}
</style>
