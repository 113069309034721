/**
 * 小程序用户接口
 */

const prefix = '/dseWeb'
export default [
  // 分页查询小程序用户
  { name: 'getAppUsers', url: `${prefix}/v1/op/appUsers`, method: 'get' },
  // 新增小程序用户
  { name: 'addAppUser', url: `${prefix}/v1/op/appUser`, method: 'post' },
  // 修改小程序用户
  { name: 'updateAppUser', url: `${prefix}/v1/op/appUser`, method: 'put' },
  // 查询单个小程序用户
  { name: 'getAppUser', url: `${prefix}/v1/op/appUser`, method: 'get' },
  // 删除小程序用户
  { name: 'deleteAppUser', url: `${prefix}/v1/op/appUser`, method: 'delete' },
  // 重置密码
  { name: 'resetAppUserPass', url: `${prefix}/v1/op/resetAppUserPass`, method: 'put' },
  // 获取指派人
  { name: 'getGanagerEngineers', url: `${prefix}/v1/op/managerEngineers`, method: 'get' }
]
