/**
 * 短信记录接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 分页查询短信发送记录
    async fetchGetSmsSendRecords({ commit, getters }, params) {
      const { getSmsSendRecords } = getters.getHttps
      const { data } = await getSmsSendRecords(params)
      return setStoreApiDate(data, commit)
    },
    // 分页查询短信模板
    async fetchGetSmsTemplates({ commit, getters }, params) {
      const { getSmsTemplates } = getters.getHttps
      const { data } = await getSmsTemplates(params)
      return setStoreApiDate(data, commit)
    },
    // 新增短信模板
    async fetchAddSmsTemplate({ commit, getters }, params) {
      const { addSmsTemplate } = getters.getHttps
      const { data } = await addSmsTemplate(params)
      return setStoreApiDate(data, commit)
    },
    // 修改短信模板
    async fetchUpdateSmsTemplate({ commit, getters }, params) {
      const { updateSmsTemplate } = getters.getHttps
      const { data } = await updateSmsTemplate(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单个短信模板
    async fetchGetSmsTemplate({ commit, getters }, templateId) {
      const { getSmsTemplate } = getters.getHttps
      const { data } = await getSmsTemplate(false, false, {
        url: `${prefix}/v1/op/smsTemplate/${templateId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 删除短信模板
    async fetchDeleteSmsTemplate({ commit, getters }, templateId) {
      const { deleteSmsTemplate } = getters.getHttps
      const { data } = await deleteSmsTemplate(false, false, {
        url: `${prefix}/v1/op/smsTemplate/${templateId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 新增短信发送记录
    async fetchAddSmsSendRecord({ commit, getters }, params) {
      const { addSmsSendRecord } = getters.getHttps
      const { data } = await addSmsSendRecord(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单个短信发送记录
    async fetchGetSmsSendRecord({ commit, getters }, recordId) {
      const { getSmsSendRecord } = getters.getHttps
      const { data } = await getSmsSendRecord(false, false, {
        url: `${prefix}/v1/op/smsSendRecord/${recordId}`
      })
      return setStoreApiDate(data, commit)
    }
  }
}
