/**
 * 项目信息接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 分页查询项目信息
    async fetchGetProjectInfos({ commit, getters }, params) {
      const { getProjectInfos } = getters.getHttps
      const { data } = await getProjectInfos(params)
      return setStoreApiDate(data, commit)
    },
    // 新增项目信息
    async fetchAddProjectInfo({ commit, getters }, params) {
      const { addProjectInfo } = getters.getHttps
      const { data } = await addProjectInfo(params)
      return setStoreApiDate(data, commit)
    },
    // 修改项目信息
    async fetchUpdateProjectInfo({ commit, getters }, params) {
      const { updateProjectInfo } = getters.getHttps
      const { data } = await updateProjectInfo(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单个项目信息
    async fetchGetProjectInfo({ commit, getters }, projectId) {
      const { getProjectInfo } = getters.getHttps
      const { data } = await getProjectInfo(false, false, {
        url: `${prefix}/v1/op/projectInfo/${projectId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 删除项目信息
    async fetchDeleteProjectInfo({ commit, getters }, projectId) {
      const { deleteProjectInfo } = getters.getHttps
      const { data } = await deleteProjectInfo(false, false, {
        url: `${prefix}/v1/op/projectInfo/${projectId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 查询单位下项目集合
    async fetchGetProjectsByCompany({ commit, getters }, params) {
      const { getProjectsByCompany } = getters.getHttps
      const { data } = await getProjectsByCompany(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单位下项目集合
    async fetchProjectTreeList({ commit, getters }, params) {
      const { projectTreeList } = getters.getHttps
      const { data } = await projectTreeList(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单位下项目集合
    async fetchMoveProject({ commit, getters }, params) {
      const { moveProject } = getters.getHttps
      const { data } = await moveProject(params)
      return setStoreApiDate(data, commit)
    },

    // 查询项目设备图层设备数量
    async fetchGetProjectLayerStatistics({ commit, getters }, params) {
      const { getProjectLayerStatistics } = getters.getHttps
      const { data } = await getProjectLayerStatistics(params)
      return setStoreApiDate(data, commit)
    },
  }
}
