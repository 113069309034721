/**
 * 短信记录接口
 */

const prefix = '/dseWeb'
export default [
  // 分页查询短信发送记录
  { name: 'getSmsSendRecords', url: `${prefix}/v1/op/smsSendRecords`, method: 'get' },
  // 分页查询短信模板
  { name: 'getSmsTemplates', url: `${prefix}/v1/op/smsTemplates`, method: 'get' },
  // 新增短信模板
  { name: 'addSmsTemplate', url: `${prefix}/v1/op/smsTemplate`, method: 'post' },
  // 修改短信模板
  { name: 'updateSmsTemplate', url: `${prefix}/v1/op/smsTemplate`, method: 'put' },
  // 查询单个短信模板
  { name: 'getSmsTemplate', url: `${prefix}/v1/op/smsTemplate`, method: 'get' },
  // 删除短信模板
  { name: 'deleteSmsTemplate', url: `${prefix}/v1/op/smsTemplate`, method: 'delete' },
  // 新增短信发送记录
  { name: 'addSmsSendRecord', url: `${prefix}/v1/op/smsSendRecord`, method: 'post' },
  // 查询单个短信发送记录
  { name: 'getSmsSendRecord', url: `${prefix}/v1/op/smsSendRecord`, method: 'get' }
]
