/**
 * 角色管理接口
 */

const prefix = '/dseWeb'
export default [
  // 分页查询角色信息
  { name: 'getSAysRoles', url: `${prefix}/v1/op/sysRoles`, method: 'get' },
  // 新增角色信息
  { name: 'addSysRole', url: `${prefix}/v1/op/sysRole`, method: 'post' },
  // 修改角色信息
  { name: 'updateSysRole', url: `${prefix}/v1/op/sysRole`, method: 'put' },
  // 查询单个角色信息
  { name: 'getSAysRole', url: `${prefix}/v1/op/sysRole`, method: 'get' },
  // 删除单个角色信息
  { name: 'deleteSAysRole', url: `${prefix}/v1/op/sysRole`, method: 'delete' },
  // 查询角色权限设置集合
  { name: 'getSysModulesByRole', url: `${prefix}/v1/op/sysModulesByRole`, method: 'get' }
]
