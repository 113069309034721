/**
 * 出库记录管理接口
 */

const prefix = '/dseWeb'
export default [
  // 分页查询备品出库记录
  { name: 'getSpareOutRecords', url: `${prefix}/v1/op/spareOutRecords`, method: 'get' },
  // 新增备品出库记录
  { name: 'addSpareOutRecord', url: `${prefix}/v1/op/spareOutRecord`, method: 'post' },
  // 删除备品出库记录
  { name: 'spareOutRecord', url: `${prefix}/v1/op/spareOutRecord`, method: 'delete' },
  // 出库记录详情
  { name: 'getSpareOutRecord', url: `${prefix}/v1/op/spareOutRecord`, method: 'get' },
  // 出库记录详情
  { name: 'updataSpareOutRecord', url: `${prefix}/v1/op/spareOutRecord`, method: 'put' }
]
