<template>
  <div class="menu-left-userinfo">
    <el-image
      :src="logo"
      style="width: 25px; background: #fff; border-radius: 4px; padding: 2px"
      :class="{ imgSize: show }"
    />
    <span v-show="!show" class="menu-left-userinfo-title">东深运维平台系统</span>
  </div>
</template>

<script>
export default {
  name: 'MuneLeftUserInfo',
  props: {
    show: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const logo = require('@/assets/image/logo.png')
    return { logo }
  }
}
</script>

<style lang="scss">
.menu-left-userinfo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: #0a263e;
  transition: all 0.3s;
  &-title {
    margin-left: 5px;
    color: #f5f5f5;
    font-size: 17px;
    font-weight: 700;
    overflow: hidden;
    transition: all 0.3s;
    white-space: nowrap;
  }
  .imgSize {
    width: 35px !important;
    height: 35px !important;
    transition: all 0.3s;
  }
}
</style>
