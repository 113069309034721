<template>
  <!--
    @name: CustomFormSelect
    @describe: 表单输Form select 下拉选择框
    @author: L
    @date: 2021-05-28
 -->
  <el-form-item :label="label" :prop="prop" :required="required">
    <el-select
      v-model="value"
      :multiple="multiple"
      :clearable="clearable"
      @change="selectChange"
      :disabled="disabled"
      :placeholder="placeholder"
      :filterable="filterable"
    >
      <el-option
        v-for="item in options"
        :key="item[keyName] || item"
        :label="item[labelName]"
        :value="item[valueName]"
      >
      </el-option>
    </el-select>
  </el-form-item>
</template>

<script>
import { ref, watch } from 'vue'
export default {
  name: 'CustomFormSelect',
  props: {
    // 是否必填
    required: {
      type: Boolean,
      default: false
    },
    // 描述
    label: {
      type: String
    },
    prop: {
      type: String
    },
    placeholder: {
      type: String
    },
    // 下拉列表
    options: {
      type: Array,
      required: true
    },
    // 绑定Key名
    keyName: {
      type: String
    },
    // 描述名
    labelName: {
      type: String,
      default: 'value'
    },
    // 值名
    valueName: {
      type: String,
      default: 'value'
    },
    // 是否多选
    multiple: {
      type: Boolean,
      default: false
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: [String, Array, Number],
      default: ''
    },
    node: {
      type: [String, Array, Number, Object],
      default: ''
    },
    clearable: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    // 绑定值
    const value = ref(props.modelValue)
    // 监听双向绑定值是否改变
    watch(
      () => props.modelValue,
      () => {
        value.value = props.modelValue
      }
    )
    // 双向绑定值
    const selectChange = nValue => {
      context.emit('update:modelValue', nValue)
      const nNode = props.options.filter(item => item[props.valueName] === nValue)
      context.emit('update:node', nNode.length > 0 ? nNode[0] : {})
      context.emit('change', nValue)
    }
    return { selectChange, value }
  }
}
</script>

<style></style>
