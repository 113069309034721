/**
 * 故障业务中心接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 分页查询设备故障记录
    async fetchGetDeviceErrorRecords({ commit, getters }, params) {
      const { getDeviceErrorRecords } = getters.getHttps
      const { data } = await getDeviceErrorRecords(params)
      return setStoreApiDate(data, commit)
    },
    // 新增设备故障草稿
    async fetchAddDeviceErrorDraft({ commit, getters }, params) {
      const { addDeviceErrorDraft } = getters.getHttps
      const { data } = await addDeviceErrorDraft(params)
      return setStoreApiDate(data, commit)
    },
    // 查询设备故障草稿
    async fetchGetDeviceErrorDraft({ commit, getters }) {
      const { getDeviceErrorDraft } = getters.getHttps
      const { data } = await getDeviceErrorDraft()
      return setStoreApiDate(data, commit)
    },
    // 新增设备故障记录
    async fetchAddDeviceErrorRecord({ commit, getters }, params) {
      const { addDeviceErrorRecord } = getters.getHttps
      const { data } = await addDeviceErrorRecord(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单个设备故障记录
    async fetchGetDeviceErrorRecord({ commit, getters }, errorId) {
      const { getDeviceErrorRecord } = getters.getHttps
      const { data } = await getDeviceErrorRecord(false, false, {
        url: `${prefix}/v1/op/deviceErrorRecord/${errorId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 删除设备故障记录
    async fetchDeleteDeviceErrorRecord({ commit, getters }, errorId) {
      const { deleteDeviceErrorRecord } = getters.getHttps
      const { data } = await deleteDeviceErrorRecord(false, false, {
        url: `${prefix}/v1/op/deviceErrorRecord/${errorId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 故障指派用户
    async fetchAddCreateErrorOrder({ commit, getters }, params) {
      const { addCreateErrorOrder } = getters.getHttps
      const { data } = await addCreateErrorOrder(params)
      return setStoreApiDate(data, commit)
    },
    // 设备故障申报办结
    async fetchAddErrorRecordComplete({ commit, getters }, params) {
      const { addErrorRecordComplete } = getters.getHttps
      const { data } = await addErrorRecordComplete(params)
      return setStoreApiDate(data, commit)
    },
    // 设备故障申报退回
    async fetchAddErrorRecordBack({ commit, getters }, params) {
      const { addErrorRecordBack } = getters.getHttps
      const { data } = await addErrorRecordBack(params)
      return setStoreApiDate(data, commit)
    },
    // 查询故障维修报告
    async fetchGetOrderRepairRecordByError({ commit, getters }, params) {
      const { getOrderRepairRecordByError } = getters.getHttps
      const { data } = await getOrderRepairRecordByError(params)
      return setStoreApiDate(data, commit)
    },
    // 查询故障流程进度
    async fatchGetDeviceErrorLogsByError({ commit, getters }, params) {
      const { getDeviceErrorLogsByError } = getters.getHttps
      const { data } = await getDeviceErrorLogsByError(params)
      return setStoreApiDate(data, commit)
    },
    // 设备故障重新提交
    async fetchGetRepeatSubmitErrorRecord({ commit, getters }, params) {
      const { getRepeatSubmitErrorRecord } = getters.getHttps
      const { data } = await getRepeatSubmitErrorRecord(params)
      return setStoreApiDate(data, commit)
    },
    // 分页查询原始故障记录
    async fetchGetDeviceErrorOriginalRecords({ commit, getters }, params) {
      const { getDeviceErrorOriginalRecords } = getters.getHttps
      const { data } = await getDeviceErrorOriginalRecords(params)
      return setStoreApiDate(data, commit)
    },
    // 分页查询原始故障记录
    async fetchDeleteDeviceErrorOriginalRecord({ commit, getters }, originalId) {
      const { deleteDeviceErrorOriginalRecord } = getters.getHttps
      const { data } = await deleteDeviceErrorOriginalRecord(false, false, {
        url: `${prefix}/v1/op/deviceErrorOriginalRecord/${originalId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 故障通过退回
    async fetchCheckOrderAuditRecord({ commit, getters }, params) {
      const { checkOrderAuditRecord } = getters.getHttps
      const { data } = await checkOrderAuditRecord(params)
      return setStoreApiDate(data, commit)
    },
    // 提交工单维修报告
    async fetchAddOrderRepairRecord({ commit, getters }, params) {
      const { addOrderRepairRecord } = getters.getHttps
      const { data } = await addOrderRepairRecord(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单位待处理故障记录集合
    async fetchErrorRecordListByCompany({ commit, getters }) {
      const { errorRecordListByCompany } = getters.getHttps
      const { data } = await errorRecordListByCompany()
      return setStoreApiDate(data, commit)
    },
    // 查询单位最新待处理故障记录集合
    async fetchNewErrorRecordByCompany({ commit, getters }) {
      const { newErrorRecordByCompany } = getters.getHttps
      const { data } = await newErrorRecordByCompany()
      return setStoreApiDate(data, commit)
    },
    // 设备故障工单退回
    async fetchErrorOrderBack({ commit, getters }, params) {
      const { errorOrderBack } = getters.getHttps
      const { data } = await errorOrderBack(params)
      return setStoreApiDate(data, commit)
    },
    // 查询设备一级单位集合
    async fetchgetfirstUnitList({ commit, getters }, params) {
      const { getfirstUnitList } = getters.getHttps
      const { data } = await getfirstUnitList(params)
      return setStoreApiDate(data, commit)
    },
    // 查询设备二级单位集合
    async fetchgetsecondUnitList({ commit, getters }, params) {
      const { getsecondUnitList } = getters.getHttps
      const { data } = await getsecondUnitList(params)
      return setStoreApiDate(data, commit)
    }
  }
}
