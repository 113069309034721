<template>
  <el-pagination
    :total="total"
    :current-page="newPage"
    :default-current-page="newPage"
    :page-size="size"
    :layout="layout"
    :prev-text="prevText"
    :next-text="nextText"
  >
    <span style="font-size: 14px">
      <span style="margin-left: 10px; color: #666; font-weight: normal">前往</span>
      <el-input
        size="mini"
        style="width: 60px"
        v-model="jumperValue"
        @change="changeJumper"
        @input="inputJumper"
      />
      <span style="margin-left: 10px; color: #666; font-weight: normal；">页</span>
    </span>
  </el-pagination>
</template>

<script>
import { nextTick, onMounted, ref, watch } from 'vue'
import { intEger } from '@/utils'
export default {
  name: 'CustomPagination',
  props: {
    // 总条数
    total: {
      type: Number,
      default: 0
    },
    // 第几页
    page: {
      type: Number,
      default: 1
    },
    // 每 页多少条
    size: {
      type: Number,
      default: 10
    },
    // 布局
    layout: {
      type: String,
      default: 'prev, pager, next, slot, sizes, total'
    },
    // 上一页文本
    prevText: {
      type: String,
      default: '上一页'
    },
    // 下一页文本
    nextText: {
      type: String,
      default: '下一页'
    },
    // 下一页文本
    modelValue: {
      type: Number,
      default: 1
    }
  },
  setup(props, context) {
    const newPage = ref(props.page)
    const jumperValue = ref(props.modelValue || props.page)
    // 处理
    const isMaxPage = page =>
      page > Math.ceil(props.total / props.size)
        ? Math.ceil(props.total / props.size)
        : Math.floor(page)
    // 值改变时
    const changeJumper = value => {
      const flootValue = isMaxPage(value)
      newPage.value = flootValue
      jumperValue.value = flootValue
      context.emit('update:modelValue', flootValue)
    }
    // 监听输入
    watch(
      () => props.modelValue,
      newvalue => {
        jumperValue.value = isMaxPage(newvalue)
        newPage.value = isMaxPage(newvalue)
      }
    )
    watch(
      () => props.total,
      () => {
        nextTick(() => {
          newPage.value = newPage.value = isMaxPage(props.page)
        })
      }
    )
    // 输入前往时郡限制
    const inputJumper = value => {
      jumperValue.value = intEger(value)
      // newPage.value = intEger(value)
    }
    onMounted(() => {
      newPage.value = newPage.value = isMaxPage(props.page)
    })
    return { newPage, jumperValue, changeJumper, intEger, inputJumper }
  }
}
</script>

<style></style>
