/**
 * 项目信息接口
 */

const prefix = '/dseWeb'
export default [
  // 分页查询项目信息
  { name: 'getProjectInfos', url: `${prefix}/v1/op/projectInfos`, method: 'get' },
  // 新增项目信息
  { name: 'addProjectInfo', url: `${prefix}/v1/op/projectInfo`, method: 'post' },
  // 修改项目信息
  { name: 'updateProjectInfo', url: `${prefix}/v1/op/projectInfo`, method: 'put' },
  // 查询单个项目信息
  { name: 'getProjectInfo', url: `${prefix}/v1/op/projectInfo`, method: 'get' },
  // 删除项目信息
  { name: 'deleteProjectInfo', url: `${prefix}/v1/op/projectInfo`, method: 'delete' },
  // 查询单位下项目集合
  { name: 'getProjectsByCompany', url: `${prefix}/v1/op/projectsByCompany`, method: 'get' },
  // 查询项目图层树集合
  { name: 'projectTreeList', url: `${prefix}/v1/op/projectTreeList`, method: 'get' },
  // 迁移项目
  { name: 'moveProject', url: `${prefix}/v1/op/moveProject`, method: 'put' },
  // 查询设备图层设备数量
  { name: 'getProjectLayerStatistics', url: `${prefix}/v1/op/projectLayerStatistics`, method: 'get' },

]
