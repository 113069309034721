/**
 * 运维报告接口实现方法
 */

// 公共方法
import {
  setStoreApiDate
} from '../utils'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 分页查询运维报告
    async fetchGetOpsReport({ commit, getters }, params) {
      const { getOpsReportPage } = getters.getHttps
      const { data } = await getOpsReportPage(params)
      return setStoreApiDate(data, commit)
    },
    // 生成运维报告
    async fetchGenerateOpsReport({ commit, getters }, params) {
      const { generateOpsReport } = getters.getHttps
      const { data } = await generateOpsReport(params)
      return setStoreApiDate(data, commit)
    },
    // 获取项目信息
    async fetchReportGetProjectInfo({ commit, getters }, params) {
      const { getReportProjectInfo } = getters.getHttps
      const { data } = await getReportProjectInfo(params)
      return setStoreApiDate(data, commit)
    },
    // 设备异常数据统计
    async fetchGetDeviceErrorCount({ commit, getters }, params) {
      const { getDeviceErrorCount } = getters.getHttps
      const { data } = await getDeviceErrorCount(params)
      return setStoreApiDate(data, commit)
    },
    // 巡检信息统计
    async fetchGetInspectCount({ commit, getters }, params) {
      const { getInspectCount } = getters.getHttps
      const { data } = await getInspectCount(params)
      return setStoreApiDate(data, commit)
    },
    // 导出运维报告
    async fetchExportReport({ commit, getters }, params) {
      const { exportReport } = getters.getHttps
      const { data } = await exportReport(params)
      return data
    }
  }
}
