/**
 * 巡检任务接口
 */

const prefix = '/dseWeb'
export default [
  // 分页查询巡检任务
  { name: 'getInspectTasks', url: `${prefix}/v1/op/inspectTasks`, method: 'get' },
  // 新增巡检任务
  { name: 'addInspectTask', url: `${prefix}/v1/op/inspectTask`, method: 'post' },
  // 巡检任务审核
  { name: 'checkInspectTask', url: `${prefix}/v1/op/checkInspectTask`, method: 'post' },
  // 查询单个巡检任务
  { name: 'getInspectTask', url: `${prefix}/v1/op/inspectTask`, method: 'get' },
  // 查询巡检流程进度
  { name: 'getInspectLogsByTask', url: `${prefix}/v1/op/inspectLogsByTask`, method: 'get' },
  // 提交巡检报告
  { name: 'addInspectRecord', url: `${prefix}/v1/op/inspectRecord`, method: 'post' },
  // 巡检一张图列表查询
  { name: 'getInspectTaskPage', url: `${prefix}/v1/op/getInspectTaskPage`, method: 'post' },
  // 巡检一张图点坐标查询接口
  { name: 'getInspectTaskCount', url: `${prefix}/v1/op/getInspectTaskCount`, method: 'post' },
  
]
