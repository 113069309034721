// 弹框组件
import Dialog from './Dialog.vue'
/* 表单组件 */
/* form 表单
   input 输入框
   input-number 步数器
   button 按钮
   select 下拉选择框
   cascader 多级下拉选择框
   tree 树,
   data-picker 日期选择器
 */
import CustomForm from './CustomForm.vue'
import CustomFormInput from './CustomFormInput.vue'
import CustomFormInputNumber from './CustomFormInputNumber.vue'
import CustomFormButton from './CustomFormButton.vue'
import CustomFormSelect from './CustomFormSelect.vue'
import CustomFormCascader from './CustomFormCascader.vue'
import CustomFormTree from './CustomFormTree.vue'
import CustomFormDatePicker from './CustomFormDatePicker.vue'
import CustomDescriptions from './CustomDescriptions.vue'
import CustomFormCheckBox from './CustomFormCheckBox.vue'
// tinymce 封装
import Tinymce from './Tinymce.vue'
// 上传文件
import CustomUpload from './CustomUpload.vue'
// tip 信息
import Tip from './Tip.vue'
// 分页组件
import CustomPagination from './CustomPagination.vue'
// highCharts 图表组件
import highchartsView from './highchartsView.vue'
// ECharts 图表组件
import EChartsView from './EChartsView.vue'
// 二维码组件
import Qrcanvas from './Qrcanvas.vue'
// 统计卡片
import CountCard from './CountCard.vue'
// 统计卡片
import CustomList from './CustomList.vue'

// 单个导出
export const _default = {
  Dialog,
  form: CustomForm,
  input: CustomFormInput,
  'input-number': CustomFormInputNumber,
  button: CustomFormButton,
  select: CustomFormSelect,
  cascader: CustomFormCascader,
  tree: CustomFormTree,
  'date-picker': CustomFormDatePicker,
  checkbox: CustomFormCheckBox,
  Tip,
  Tinymce,
  pagination: CustomPagination,
  upload: CustomUpload,
  highcharts: highchartsView,
  echarts: EChartsView,
  Qrcanvas,
  descriptions: CustomDescriptions,
  CountCard,
  list: CustomList
}
// 全局导出
export default {
  install(vue) {
    Object.keys(_default).map(name => {
      vue.component(`ml-${name.toLowerCase()}`, _default[name])
    })
  }
}
