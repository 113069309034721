/**
 * 通知通告发布接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 分页查询通知通告发布
    async fetchGetCompanyNotices({ commit, getters }, params) {
      const { getCompanyNotices } = getters.getHttps
      const { data } = await getCompanyNotices(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单个通知通告发布
    async fetchGetCompanyNotice({ commit, getters }, recordId) {
      const { getCompanyNotice } = getters.getHttps
      const { data } = await getCompanyNotice(false, false, {
        url: `${prefix}/v1/op/companyNotice/${recordId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 新增通知通告发布
    async fetchAddCompanyNotice({ commit, getters }, params) {
      const { addCompanyNotice } = getters.getHttps
      const { data } = await addCompanyNotice(params)
      return setStoreApiDate(data, commit)
    },
    // 修改通知通告发布
    async fetchUpdateCompanyNotice({ commit, getters }, params) {
      const { updateCompanyNotice } = getters.getHttps
      const { data } = await updateCompanyNotice(params)
      return setStoreApiDate(data, commit)
    },
    // 删除通知通告发布
    async fetchDeleteCompanyNotice({ commit, getters }, recordId) {
      const { deleteCompanyNotice } = getters.getHttps
      const { data } = await deleteCompanyNotice(false, false, {
        url: `${prefix}/v1/op/companyNotice/${recordId}`
      })
      return setStoreApiDate(data, commit)
    }
  }
}
