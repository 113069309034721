/**
 * 流程管理接口
 */

const prefix = '/dseWeb'
export default [
  // 分页查询工单审核流程
  { name: 'getOrderProcesss', url: `${prefix}/v1/op/orderProcesss`, method: 'get' },
  // 保存工单审核流程
  { name: 'addOrderProcess', url: `${prefix}/v1/op/orderProcess`, method: 'post' },
  // 修改工单审核流程
  { name: 'updateOrderProcess', url: `${prefix}/v1/op/orderProcess`, method: 'put' },
  // 查询单个工单审核流程
  { name: 'getOrderProcess', url: `${prefix}/v1/op/orderProcess`, method: 'get' }
]
