/**
 * 巡检任务接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 分页查询巡检任务
    async fetchGetInspectTasks({ commit, getters }, params) {
      const { getInspectTasks } = getters.getHttps
      const { data } = await getInspectTasks(params)
      return setStoreApiDate(data, commit)
    },
    // 新增巡检任务
    async fetchAddInspectTask({ commit, getters }, params) {
      const { addInspectTask } = getters.getHttps
      const { data } = await addInspectTask(params)
      return setStoreApiDate(data, commit)
    },
    // 巡检任务审核
    async fetchCheckInspectTask({ commit, getters }, params) {
      const { checkInspectTask } = getters.getHttps
      const { data } = await checkInspectTask(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单个巡检任务
    async fetchGetInspectTask({ commit, getters }, inspectTaskId) {
      const { getInspectTask } = getters.getHttps
      const { data } = await getInspectTask(false, false, {
        url: `${prefix}/v1/op/inspectTask/${inspectTaskId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 查询巡检流程进度
    async fetchGetInspectLogsByTask({ commit, getters }, params) {
      const { getInspectLogsByTask } = getters.getHttps
      const { data } = await getInspectLogsByTask(params)
      return setStoreApiDate(data, commit)
    },
    // 提交巡检报告
    async fetchAddInspectRecord({ commit, getters }, params) {
      const { addInspectRecord } = getters.getHttps
      const { data } = await addInspectRecord(params)
      return setStoreApiDate(data, commit)
    },
    // 巡检一张图列表查询
    async fetchGetInspectTaskPage({ commit, getters }, params) {
      const { getInspectTaskPage } = getters.getHttps
      const { data } = await getInspectTaskPage(params)
      return setStoreApiDate(data, commit)
    },
    // 巡检一张图点坐标查询接口
    async fetchGetInspectTaskCount({ commit, getters }, params) {
      const { getInspectTaskCount } = getters.getHttps
      const { data } = await getInspectTaskCount(params)
      return setStoreApiDate(data, commit)
    },
  }
}
