/**
 * 登陆接口
 */
const prefix = '/dseWeb'
export default [
  // 登陆接口
  { name: 'Dologin', url: `${prefix}/v1/dologin`, method: 'post' },
  // 登陆toKen校验接口
  { name: 'CurrentUser', url: `${prefix}/getSysUserInfo`, method: 'get' },
  // 登陆toKen校验接口
  { name: 'LoginOut', url: `${prefix}/v1/loginOut`, method: 'delete' },
  // 获取验证码
  { name: 'GetVeryCode', url: `${prefix}/v1/getVeryCode`, method: 'get' }
]
