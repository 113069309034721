/**
 * 单位管理接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 分页查询单位信息
    async fetchGetCompanyInfos({ commit, getters }, params) {
      const { getCompanyInfos } = getters.getHttps
      const { data } = await getCompanyInfos(params)
      return setStoreApiDate(data, commit)
    },
    // 新增单位信息
    async fetchAddCompanyInfo({ commit, getters }, params) {
      const { addCompanyInfo } = getters.getHttps
      const { data } = await addCompanyInfo(params)
      return setStoreApiDate(data, commit)
    },
    // 修改单位信息
    async fetchUpdateCompanyInfo({ commit, getters }, params) {
      const { updateCompanyInfo } = getters.getHttps
      const { data } = await updateCompanyInfo(params)
      return setStoreApiDate(data, commit)
    },
    // 删除单位信息
    async fetchDeleteCompanyInfo({ commit, getters }, companyId) {
      const { deleteCompanyInfo } = getters.getHttps
      const { data } = await deleteCompanyInfo(false, false, {
        url: `${prefix}/v1/op/companyInfo/${companyId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 查询单个单位信息
    async fetchGetCompanyInfo({ commit, getters }, companyId) {
      const { getCompanyInfo } = getters.getHttps
      const { data } = await getCompanyInfo(false, false, {
        url: `${prefix}/v1/op/companyInfo/${companyId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 单位负责人登录
    async fetchcompanyManagerLogin({ commit, getters }, params) {
      const { companyManagerLogin } = getters.getHttps
      const { data } = await companyManagerLogin(params)
      return setStoreApiDate(data, commit)
    }
  }
}
