<template>
  <!--
    @name: CustomFormTree
    @describe: 表单输Form Tree 树
    @author: L
    @date: 2021-06-25
 -->
  <el-form-item :label-width="labelWidth" :label="label" :prop="prop" :required="required">
    <el-scrollbar :height="height" :class="treeClass">
      <el-tree
        v-if="data.length > 0"
        :props="props"
        accordion
        :load="load"
        :lazy="lazy"
        :data="data"
        :default-checked-keys="moduleIds"
        :default-expanded-keys="moduleIds"
        :show-checkbox="checkbox"
        :node-key="nodeKey"
        :default-expand-all="expandAll"
        @check="checkChange"
        ref="customTreeRef"
      >
        <template #default="{ node, data }">
          <slot name="default" :data="{ node, data }">
            <span>{{ node.label }}</span>
          </slot>
        </template>
      </el-tree>
    </el-scrollbar>
  </el-form-item>
</template>

<script>
import { ref, watch } from 'vue'
import { filterTreeData } from '@/utils'
export default {
  name: 'CustomFormTree',
  props: {
    height: {
      type: String,
      default: '50px'
    },
    // 是是必填
    required: {
      type: Boolean,
      default: false
    },
    // 是否展开所有
    expandAll: {
      type: Boolean,
      default: false
    },
    // 描述
    label: {
      type: String
    },
    // 描述宽度
    labelWidth: {
      type: String
    },
    prop: {
      type: String
    },
    placeholder: {
      type: String
    },
    treeClass: {
      type: String
    },
    // 远程路径
    load: {
      type: String
    },
    // 懒加载子节点
    lazy: {
      type: Boolean,
      default: false
    },
    nodeKey: {
      type: String,
      default: 'label'
    },
    // label children disabled isLeaf
    props: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 原始列表
    data: {
      type: Array,
      default: () => []
    },
    // 是否显示筛选框
    checkbox: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    halfCheckedKeys: {
      type: Array,
      default: () => []
    },
    selectData: {
      type: Array,
      default: () => []
    }
  },
  setup(props, context) {
    const customTreeRef = ref()
    // 绑定值
    const moduleIds = ref(props.modelValue || [])
    const halfCheckedKeysRef = ref(props.halfCheckedKeys || [])
    // 监听双向绑定值是否改变
    watch(
      () => props.modelValue,
      () => {
        moduleIds.value = props.modelValue
      }
    )
    // 双向绑定值
    const checkChange = (node, nvalue) => {
      halfCheckedKeysRef.value = nvalue.halfCheckedKeys
      moduleIds.value = nvalue.checkedKeys
      const sesectDatas = filterTreeData(
        props.data,
        [...nvalue.checkedKeys, ...nvalue.halfCheckedKeys],
        { field: props.nodeKey }
      )
      context.emit('update:modelValue', moduleIds.value)
      context.emit('update:halfCheckedKeys', halfCheckedKeysRef.value)
      context.emit('update:selectData', sesectDatas)
    }

    // 为节点更新数据
    const updateKeyChildren = customTreeRef.value && customTreeRef.value.updateKeyChildren

    return { customTreeRef, checkChange, moduleIds ,updateKeyChildren}
  }
}
</script>

<style></style>
