t
<template>
  <!--
    @name: CustomDescriptions
    @describe: Descriptions
    @author: L
    @date: 2021-07-06
 -->
  <el-descriptions
    :title="title"
    :size="size"
    :column="column"
    :border="border"
    :direction="direction"
  >
    <template v-if="extra" #extra>
      <component :is="{ template: extra }"></component>
    </template>
    <el-descriptions-item
      :min-width="minWidth"
      :width="width"
      v-for="item in list"
      :key="keyName ? item[keyName] : item"
      :label="labelName ? item[labelName] : item.label"
      :span="item.span ? item.span : 1"
    >
      <component v-if="item.slot" :is="{ template: item.slot }"></component>
      <span v-else>{{ valueName ? item[valueName] : item.value }}</span>
    </el-descriptions-item>
  </el-descriptions>
</template>

<script>
export default {
  name: 'Descriptions',
  emits: ['click-item'],
  props: {
    title: {
      // 标题文本，显示在左上方
      type: String
    },
    width: {
      // 宽度
      type: [String, Number]
    },
    minWidth: {
      // 宽度
      type: [String, Number]
    },
    extra: {
      // 操作区文本，显示在右上方
      type: String
    },
    direction: {
      // vertical / horizontal
      type: String,
      default: 'horizontal'
    },
    border: {
      type: Boolean,
      default: false
    },
    column: {
      type: Number,
      default: 3
    },
    size: {
      // medium / small / mini
      type: String
    },
    keyName: {
      type: String
    },
    labelName: {
      type: String
    },
    valueName: {
      type: String
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  setup(props, context) {
    const clickItem = item => {
      context.emit('click-item', item)
    }
    return { clickItem }
  }
}
</script>

<style></style>
