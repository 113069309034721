/**
 * 故障业务中心接口
 */

const prefix = '/dseWeb'
export default [
  // 分页查询设备故障记录
  { name: 'getDeviceErrorRecords', url: `${prefix}/v1/op/deviceErrorRecords`, method: 'get' },
  // 新增设备故障草稿
  { name: 'addDeviceErrorDraft', url: `${prefix}/v1/op/deviceErrorDraft`, method: 'post' },
  // 查询设备故障草稿
  { name: 'getDeviceErrorDraft', url: `${prefix}/v1/op/deviceErrorDraft`, method: 'get' },
  // 新增设备故障记录
  { name: 'addDeviceErrorRecord', url: `${prefix}/v1/op/deviceErrorRecord`, method: 'post' },
  // 查询单个设备故障记录
  { name: 'getDeviceErrorRecord', url: `${prefix}/v1/op/deviceErrorRecord`, method: 'get' },
  // 删除设备故障记录
  { name: 'deleteDeviceErrorRecord', url: `${prefix}/v1/op/deviceErrorRecord`, method: 'delete' },
  // 故障指派用户
  { name: 'addCreateErrorOrder', url: `${prefix}/v1/op/createErrorOrder`, method: 'post' },
  // 设备故障申报办结
  { name: 'addErrorRecordComplete', url: `${prefix}/v1/op/errorRecordComplete`, method: 'post' },
  // 设备故障申报退回
  { name: 'addErrorRecordBack', url: `${prefix}/v1/op/errorRecordBack`, method: 'post' },
  // 查询故障维修报告
  {
    name: 'getOrderRepairRecordByError',
    url: `${prefix}/v1/op/orderRepairRecordByError`,
    method: 'get'
  },
  // 查询故障流程进度
  {
    name: 'getDeviceErrorLogsByError',
    url: `${prefix}/v1/op/deviceErrorLogsByError`,
    method: 'get'
  },
  // 设备故障重新提交
  {
    name: 'getRepeatSubmitErrorRecord',
    url: `${prefix}/v1/op/repeatSubmitErrorRecord`,
    method: 'post'
  },
  // 分页查询原始故障记录
  {
    name: 'getDeviceErrorOriginalRecords',
    url: `${prefix}/v1/op/deviceErrorOriginalRecords`,
    method: 'get'
  },
  // 删除原始故障记录
  {
    name: 'deleteDeviceErrorOriginalRecord',
    url: `${prefix}/v1/op/deviceErrorOriginalRecord`,
    method: 'delete'
  },
  // 故障通过退回
  {
    name: 'checkOrderAuditRecord',
    url: `${prefix}/v1/op/checkOrderAuditRecord`,
    method: 'post'
  },
  // 提交工单维修报告
  {
    name: 'addOrderRepairRecord',
    url: `${prefix}/v1/op/orderRepairRecord`,
    method: 'post'
  },
  // 查询单位待处理故障记录集合
  {
    name: 'errorRecordListByCompany',
    url: `${prefix}/v1/op/errorRecordListByCompany`,
    method: 'get'
  },
  // 查询单位最新待处理故障记录集合
  {
    name: 'newErrorRecordByCompany',
    url: `${prefix}/v1/op/newErrorRecordByCompany`,
    method: 'get'
  },
  // 设备故障工单退回
  {
    name: 'errorOrderBack',
    url: `${prefix}/v1/op/errorOrderBack`,
    method: 'post'
  },
  // 查询设备一级单位集合
  {
    name: 'getfirstUnitList',
    url: `${prefix}/v1/op/firstUnitList`,
    method: 'get'
  },
  // 查询设备二级单位集合
  {
    name: 'getsecondUnitList',
    url: `${prefix}/v1/op/secondUnitList`,
    method: 'get'
  }
]
