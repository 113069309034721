/**
 * 出库记录接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 分页查询备品出库记录
    async fetchGetSpareOutRecords({ commit, getters }, params) {
      const { getSpareOutRecords } = getters.getHttps
      const { data } = await getSpareOutRecords(params)
      return setStoreApiDate(data, commit)
    },
    // 新增备品出库记录
    async fetchAddSpareOutRecord({ commit, getters }, params) {
      const { addSpareOutRecord } = getters.getHttps
      const { data } = await addSpareOutRecord(params)
      return setStoreApiDate(data, commit)
    },
    // 删除备品出库记录
    async fetchspareOutRecord({ commit, getters }, recordId) {
      const { spareOutRecord } = getters.getHttps
      const { data } = await spareOutRecord(false, false, {
        url: `${prefix}/v1/op/spareOutRecord/${recordId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 出库记录详情
    async fetchGetSpareOutRecord({ commit, getters }, recordId) {
      const { getSpareOutRecord } = getters.getHttps
      const { data } = await getSpareOutRecord(false, false, {
        url: `${prefix}/v1/op/spareOutRecord/${recordId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 出库记录修改
    async fetchUpdataSpareOutRecord({ commit, getters }, params) {
      const { updataSpareOutRecord } = getters.getHttps
      const { data } = await updataSpareOutRecord(params)
      return setStoreApiDate(data, commit)
    }
  }
}
