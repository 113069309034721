/**
 * 工单草稿接口
 */

const prefix = '/dseWeb'
export default [
  // 新增工单草稿
  { name: 'addOrderDraft', url: `${prefix}/v1/op/orderDraft`, method: 'post' },
  // 查询工单草稿
  { name: 'getOrderDraft', url: `${prefix}/v1/op/orderDraft`, method: 'get' }
]
