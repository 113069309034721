<template>
  <ml-dialog
    width="1000px"
    ref="uploadDialogRef"
    title="资源管理器"
    @click-submit="uploadDialogSubmit"
    @click-close="tabIndex = '0'"
  >
    <template #body>
      <div class="upload-dlg-body">
        <el-tabs type="border-card" class="upload-dlg-body-tab" v-model="tabIndex">
          <el-tab-pane label="本地资源">
            <ml-upload
              multiple
              autoUpload
              :showFileList="false"
              :action="action"
              :data="{ linkType: 'error' }"
              :headers="{ Authorization: toKen }"
              name="files"
              @on-success="onSuccess"
            >
              <el-button
                :style="{ marginBottom: '10px' }"
                size="medium"
                icon="el-icon-circle-plus-outline"
                type="primary"
              >
                上传附件
              </el-button>
            </ml-upload>
            <el-scrollbar height="280px">
              <ml-list
                icon
                fileName="fileName"
                :list="attachVosRe.local"
                :style="{ marginTop: '10px' }"
              />
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane label="服务器资源">
            <div class="upload-dlg-body-tab-serve">
              <div class="upload-dlg-body-tab-serve-tree">
                <el-tree
                  :style="{ height: '100%', 'overflow-x': 'hidden' }"
                  ref="folderTreeRef"
                  :data="treeData"
                  node-key="id"
                  :expand-on-click-node="false"
                  :default-expanded-keys="nConfig"
                  @node-click="nodeCLick"
                  highlight-current
                >
                  <template #default="{ node }">
                    <span class="upload-tree-node" v-if="node.level === 1">
                      <span :class="`tree-span-${node.level}`" :title="node.label">
                        {{ node.label }}
                      </span>
                    </span>
                    <span class="upload-tree-node" v-if="node.level === 2">
                      <span :class="`tree-span-${node.level}`" :title="node.label">
                        {{ node.label }}
                      </span>
                    </span>
                  </template>
                </el-tree>
              </div>
              <div class="upload-dlg-body-tab-serve-item" v-if="!isActiveDir">
                <!-- <el-scrollbar height="100%"> -->
                <div
                  :style="{
                    display: 'flex',
                    flexWrap: 'wrap',
                    paddingLeft: '15px',
                    height: '100%',
                    overflowX: 'hidden'
                  }"
                  v-if="folderData.length > 0"
                >
                  <folder-item
                    v-for="item in folderData"
                    :key="item"
                    :name="item.folderName"
                    @click="clickDir(item)"
                  />
                </div>
                <el-empty v-else description="暂无文件夹"></el-empty>
                <!-- </el-scrollbar> -->
              </div>
              <div class="upload-dlg-body-tab-serve-item" v-else>
                <el-scrollbar :height="`${tabberHeight(380)}px`">
                  <div :style="{ display: 'flex', flexWrap: 'wrap' }" v-if="fileData.length > 0">
                    <folder-item
                      v-for="item in fileData"
                      :key="item"
                      :name="item.fileName"
                      @click="clickFile(item)"
                      :isTrue="isActiveFileFn(item)"
                    />
                  </div>
                  <el-empty v-else description="暂无文件"></el-empty>
                </el-scrollbar>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </template>
  </ml-dialog>
</template>

<script>
import { reactive, ref } from '@vue/reactivity'
import { computed, onMounted, watch } from '@vue/runtime-core'
import { BASEURL, FILEUPLOADURL } from '@API'
import { useStore } from 'vuex'
import { getTabberData, tabberHeight } from '@/utils'
import FolderItem from './folderItem.vue'

export default {
  name: 'UploadDlg',
  components: {
    FolderItem
  },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    config: {
      type: Array,
      default: () => []
    }
  },
  setup(props, centont) {
    const { getters, dispatch } = useStore()
    const toKen = computed(() => getters.getToken)
    // tab
    const tabIndex = ref('0')
    watch(
      () => tabIndex.value,
      newvalue => {
        attachVosRe.local = []
        attachVosRe.server = []
        fileData.value = []
        isActiveDir.value = ''
        if (newvalue === '1') {
          if (nConfig.value[1]) {
            folderTreeRef.value.setCurrentKey(nConfig.value[1])
          } else if (nConfig.value[0]) {
            folderTreeRef.value.setCurrentKey(nConfig.value[0])
          }
          getFolderData()
        }
      }
    )

    // new config
    const nConfig = ref(props.config)
    watch(
      () => props.config,
      newvalue => {
        nConfig.value = [...newvalue]
      }
    )

    // dlg dom
    const uploadDialogRef = ref()
    watch(
      () => props.modelValue,
      () => {
        attachVosRe.local = []
        attachVosRe.server = []
        fileData.value = []
        isActiveDir.value = ''
      }
    )

    // 提交方法
    const uploadDialogSubmit = () => {
      if (tabIndex.value === '0') {
        centont.emit('update:modelValue', attachVosRe.local)
        uploadDialogRef.value.showDialog = false
      } else {
        centont.emit('update:modelValue', attachVosRe.server)
        tabIndex.value = '0'
        uploadDialogRef.value.showDialog = false
      }
    }
    // 上传附件
    const attachVosRe = reactive({
      local: [],
      server: []
    })
    const action = ref(`${BASEURL}${FILEUPLOADURL}`)
    const onSuccess = files => {
      if (files.data) {
        attachVosRe.local = [...attachVosRe.local, ...files.data]
      }
    }

    // 树
    const folderTreeRef = ref()
    const treeData = ref([])
    const getTreeDate = async () => {
      const { data } = await getTabberData(dispatch, 'fetchGetProjectFolderTreeList', {})
      if (data && data.length > 0) {
        treeData.value = data
      }
    }

    // 文件夹配置
    const folderData = ref([])
    // 处理文件夹列表
    const getFolderData = async () => {
      const [companyId, projectId] = nConfig.value
      const projectParams = {}
      if (companyId) {
        projectParams.companyId = companyId
      }
      if (companyId) {
        projectParams.projectId = projectId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectFolders', projectParams)

      if (data && data.length > 0) {
        folderData.value = [...data]
      } else {
        folderData.value = []
      }
    }
    // 点击树
    const nodeCLick = (data, node) => {
      if (node.level === 1) {
        nConfig.value = [data.id]
      } else if (node.level === 2) {
        nConfig.value = [node.parent.data.id, data.id]
      }
      isActiveDir.value = ''
      folderData.value = []
      fileData.value = []
      attachVosRe.server = []
      getFolderData()
    }

    // 获取列表
    const fileData = ref([])
    const getUserTabberData = async () => {
      const { data } = await getTabberData(dispatch, 'fetchGetProjectFolderFiles', {
        folderId: isActiveDir.value
      })
      fileData.value = data || []
    }

    // 单击文件夹
    const isActiveDir = ref('')
    const clickDir = data => {
      isActiveDir.value = data.folderId
      getUserTabberData()
    }
    // 单击文件
    const clickFile = data => {
      if (attachVosRe.server.map(item => item.fileId).includes(data.fileId)) {
        attachVosRe.server = attachVosRe.server.filter(item => item.fileId !== data.fileId)
      } else {
        const suffix = data.fileName.substring(data.fileName.lastIndexOf('.') + 1)
        attachVosRe.server = [
          ...attachVosRe.server,
          {
            fileId: data.fileId,
            fileName: data.fileName,
            filePath: data.filePath,
            suffix
          }
        ]
      }
    }

    const isActiveFileFn = item => {
      let isTrue = false
      if (attachVosRe.server.length > 0) {
        const fileIds = attachVosRe.server.map(v => v.fileId)
        isTrue = fileIds.includes(item.fileId)
      } else {
        isTrue = false
      }
      return isTrue
    }

    onMounted(() => {
      getTreeDate()
    })

    return {
      tabIndex,
      uploadDialogRef,
      uploadDialogSubmit,
      action,
      onSuccess,
      toKen,
      attachVosRe,
      treeData,
      nodeCLick,
      tabberHeight,
      nConfig,
      folderTreeRef,
      folderData,
      clickDir,
      isActiveDir,
      clickFile,
      fileData,
      isActiveFileFn
    }
  }
}
</script>

<style lang="scss">
.upload-dlg-body {
  height: 400px;
  &-tab {
    width: 960px;
    height: 100%;
    &-serve {
      height: 340px;
      display: flex;
      &-tree {
        width: 300px;
        height: 100%;
        border-right: 1px solid #d8d8d8;
        .el-tree {
          background-color: transparent;
        }
        .el-tree-node__content {
          line-height: 20px;
        }
      }
      &-item {
        flex: 1;
      }
      .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
        background-color: #409eff;
        color: #fff;
      }
    }
  }
  .el-tabs--border-card > .el-tabs__content {
    padding: 15px {
      right: 0;
    }
  }
}
</style>
