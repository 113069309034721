/**
 * 知识库管理接口
 */

const prefix = '/dseWeb'
export default [
  // 分页查询项目文件夹
  { name: 'getProjectFolders', url: `${prefix}/v1/op/projectFolders`, method: 'get' },
  // 新增项目文件夹
  { name: 'addProjectFolder', url: `${prefix}/v1/op/projectFolder`, method: 'post' },
  // 修改项目文件夹
  { name: 'updateProjectFolder', url: `${prefix}/v1/op/projectFolder`, method: 'put' },
  // 查询单个项目文件夹
  { name: 'getProjectFolder', url: `${prefix}/v1/op/projectFolder`, method: 'get' },
  // 查询项目文件夹树集合
  { name: 'getProjectFolderTreeList', url: `${prefix}/v1/op/projectFolderTreeList`, method: 'get' },
  // 删除项目文件夹
  { name: 'deleteProjectFolder', url: `${prefix}/v1/op/projectFolder`, method: 'delete' },
  // 分页查询项目文件
  { name: 'getProjectFolderFiles', url: `${prefix}/v1/op/projectFolderFiles`, method: 'get' },
  // 新增项目文件
  { name: 'addProjectFolderFile', url: `${prefix}/v1/op/projectFolderFile`, method: 'post' },
  // 删除项目文件
  { name: 'deleteProjectFolderFile', url: `${prefix}/v1/op/projectFolderFile`, method: 'delete' },
  // 查询单个项目文件
  { name: 'getProjectFolderFile', url: `${prefix}/v1/op/projectFolderFile`, method: 'get' },
  // 初始化项目目录
  { name: 'initProjectFolder', url: `${prefix}/v1/op/initProjectFolder`, method: 'get' },
  // 项目文件批量下载
  {
    name: 'projectFileBatchDownload',
    url: `${prefix}/v1/op/projectFileBatchDownload`,
    method: 'get'
  },
  // 查询文件夹下的文件夹
  {
    name: 'getProjectFolderSupers',
    url: `${prefix}/v1/op/projectFolders`,
    method: 'get'
  }
]
