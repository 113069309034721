<template>
  <el-dialog
    :title="title"
    :width="width"
    :custom-class="customClass"
    :before-close="beforeClose"
    @open="open"
    append-to-body
    :show-close="showClose"
    v-model="showDialog"
  >
    <slot name="body">
      <i class="icon-default" :class="icon"></i>
      {{ content }}
      <slot name="describe"></slot>
    </slot>
    <!-- 底部按钮组 -->
    <template #footer v-if="showBtn">
      <slot name="btn">
        <div>
          <el-button v-if="showCloseBtn" @click="clickClose">{{ closeText }}</el-button>
          <el-button
            :style="{ width: '120px', 'margin-left': '20px' }"
            v-if="showSubmitBtn"
            type="primary"
            @click="clickSubmit"
            :loading="btnLoading"
          >
            {{ submitText }}
          </el-button>
        </div>
      </slot>
    </template>
  </el-dialog>
</template>

<script>
// 父子件绑定 ref 给 showDialog 传值 Boolean 显示对话框
import { ref } from 'vue'
export default {
  name: 'Dialog',
  emits: ['click-close', 'click-submit', 'before-close', 'open'],
  props: {
    title: {
      type: String,
      default: '提示'
    },
    content: {
      type: String,
      default: '这是Dialog'
    },
    submitText: {
      type: String,
      default: '确定'
    },
    closeText: {
      type: String,
      default: '取消'
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: 'el-icon-warning'
    },
    width: {
      type: String,
      default: '300px'
    },
    customClass: {
      type: String
    },
    showBtn: {
      type: Boolean,
      default: true
    },
    center: {
      type: Boolean,
      default: true
    },
    showClose: {
      type: Boolean,
      default: false
    },
    showCloseBtn: {
      type: Boolean,
      default: true
    },
    showSubmitBtn: {
      type: Boolean,
      default: true
    },
    defaultClose: {
      type: Boolean,
      default: false
    },
    btnLoading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const showDialog = ref(false)
    const clickClose = () => {
      if (!props.defaultClose) {
        showDialog.value = false
      }
      context.emit('click-close')
    }
    // 订阅关闭回调方法
    const beforeClose = done => {
      context.emit('before-close')
      done()
    }

    const open = () => {
      context.emit('open')
    }

    // 订阅确定按钮事件
    const clickSubmit = () => {
      context.emit('click-submit')
    }

    return {
      beforeClose,
      clickSubmit,
      showDialog,
      clickClose,
      open
    }
  }
}
</script>

<style lang="scss">
.el-dialog {
  border-radius: 10px;
}
.el-dialog__header {
  border-bottom: 1px dashed #eee;
  margin: 0 20px;
}
.icon-default {
  font-size: 22px;
  vertical-align: middle !important;
  color: #e6a23c;
  margin-right: 5px;
}
.el-dialog__headerbtn {
  position: inherit !important;
}
.el-dialog__body,
.el-dialog__footer,
.el-dialog__header {
  padding: 20px 10px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-dialog__footer,
.el-dialog__body {
  justify-content: center;
}
</style>
